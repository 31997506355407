/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Badge } from 'react-bootstrap';
import DataTableFilter from "../../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../../App";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../../Common/CommonPagination";
import CommonewModel from "../../Common/CommonewModel";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";

const Box = (props) => {
    const { RouteName } = props;
    const { boxList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Masters[3]?.Edit || PermissionsAccess?.Masters[3]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "box_list",
                "request": {
                    "status": userType === "active" ? true : userType === "inactive" ? false : "",
                    "limit": userLimit,
                    "page_no": currentPage,
                    "search": userSearch.trim()
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [websocket, userSearch, userType, currentPage, userLimit]);

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url: "",
            action: false
        }));
    }, []);

    const AddNewBoxModal = () => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Create New Box",
            subtitle: "Create New Box",
            modalType: "form",
            button: "Create"
        })
    };

    const UpdateBoxModal = (value) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Update New Box",
            subtitle: "Update New Box",
            modalType: "form",
            button: "Update",
            Data: value
        })
    };

    return (<section className="Main_section">
        <DataTableFilter
            filterType={""}
            searchType={"Box Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Masters[3]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Box",
                function: AddNewBoxModal
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        <div className="tableView">
            <Scrollbars
                style={{ height: boxList?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)" }}
                renderView={props => <div {...props} className="view" />}
                className="ScrollbarsSidebar"
            >
                <Table bordered hover >
                    <thead>
                        <tr>
                            <th>Sr no</th>
                            <th>Box Name</th>
                            <th>Box Height</th>
                            <th>Box Width</th>
                            <th>Box Length</th>
                            <th>Status</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Masters[3]?.Edit) && (<th>Action</th>)}
                        </tr>

                    </thead>
                    <tbody>
                        {boxList?.list?.map((elm, index) => {
                            return (<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, boxList?.pagination?.current_page, boxList?.pagination?.record_limit)}
                                </td>
                                <td>{elm?.box_name}</td>
                                <td>{elm?.box_height}</td>
                                <td>{elm?.box_width}</td>
                                <td>{elm?.box_length}</td>
                                <td>{elm?.is_active ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}</td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Masters[3]?.Edit) && (<td>
                                    <Link className="btn edittable" onClick={() => UpdateBoxModal(elm)}>
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>
                                </td>)}
                            </tr>)
                        })}
                        {boxList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="7">Box list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {boxList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={boxList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModalNew?.open && (<CommonewModel setCurrentPage={setCurrentPage} setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}
    </section>)
}

export default Box;
/* eslint-disable */
import React, { useState } from "react";
import { Table, Button, Form } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import "react-datepicker/dist/react-datepicker.css";

const StockTransfer = (props) => {
    const { RouteName } = props;

    

    return(<section className="Main_section">
        <Scrollbars 
            style={{ height: "calc(100vh - 100px)" }} 
            renderView={props => <div {...props} className="view"/>}
            
            className="ScrollbarsSidebar"
        >
            <div className="filtr_Form">
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Select Company</Form.Label>
                                <select className="form-control">
                                    <option value="EM Delhi">EM Delhi</option>
                                </select>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Select Warehouse</Form.Label>
                                <select className="form-control">
                                    <option value="all">WH001- (testing)</option>
                                    <option value="active">WH002- (Mahesh)</option>
                                </select>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="HeaderDivider">
                        <h6 className="subtitleForm">Search Result:</h6>
                        <div className="formsearchList">
                            <div className="row">
                                <div className="col-md-3" >
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <div className="formData">Beacon Helmet</div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-3" >
                                    <Form.Group className="mb-3" >
                                        <Form.Label>SKU Code</Form.Label>
                                        <div className="formData">Beacon Helmet</div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-3" >
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Availble Stock</Form.Label>
                                        <div className="formData">238</div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-3">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Quantity</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Quantity" />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                        <div className="formsearchList">
                            <div className="row">
                                <div className="col-md-3" >
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <div className="formData">Beacon Helmet</div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-3" >
                                    <Form.Group className="mb-3" >
                                        <Form.Label>SKU Code</Form.Label>
                                        <div className="formData">Beacon Helmet</div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-3" >
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Availble Stock</Form.Label>
                                        <div className="formData">238</div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-3">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Quantity</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Quantity" />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                        <div className="btnGroupswraps">
                            <Button className="Addbtn">Preview</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tableView">
                <Table bordered hover >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Product Code</th>
                            <th>Product Name</th>
                            <th>Qty</th>
                            <th>Unit Price</th>
                            <th>Total Price</th>
                            <th>Availble Qty</th>
                            <th style={{ width: "70px" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Beacon Helmet</td>
                            <td>Beacon Helmet</td>
                            <td>1</td>
                            <td>3997</td>
                            <td>3997</td>
                            <td>238</td>
                            <td>
                                <Button className="btn edittable">
                                    <i className="bi bi-pencil-square"></i>    
                                </Button>
                                <Button className="btn edittable" style={{ marginLeft: "7px" }}>
                                    <i className="bi bi-trash" style={{ color: "red" }}></i> 
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="btnGroupswraps">
                    <Button className="Addbtn"><i className="bi bi-plus-circle"></i> Add More Products</Button>
                    <Button className="Resetbtn"><i className="bi bi-check2-circle"></i> Stock Transfer</Button>
                </div>
            </div>
        </Scrollbars>
    </section>)
}

export default StockTransfer;
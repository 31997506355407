/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PRODUCT_APPLICATIONS_URL, CATEGORY_URL, SUB_CATEGORY_URL, USER_MANAGEMENT_URL, FRANCHISE_USER_REPORT_URL, RM_LOGIN_URL, FRANCHISE_TYPE_REPORT_URL, STORE_DETAILS_REPORT_URL, PRODUCT_GROUP_URL, PRODUCT_CREATION_URL, STOCK_INWARD_URL, STOCK_LEVEL_URL, STORE_DETAILS_URL, STORE_INWARD_LOG_URL, WAREHOUSE_URL, WAREHOUSE_QUICK_URL, DISPATCH_LIST_URL, STATES_URL, CITIES_URL, PINE_CODES_URL, BOX_URL, COURIERS_URL, PURCHASE_LIST_URL, REGION_MASTER_URL, REFURBISHED_REPORT_LIST, RESALE_BRANDS_URL, SETTINGS_REFURBISHED_REPORT, AGE_VALUE_REFURBISHED_REPORT, POINT_VALUE_REFURBISHED_REPORT, FRANCHISE_ORDERSREPORT_URL, PURCHASE_ORDER_URL, PO_POSTING_URL, PO_APPROVALS_URL, FRANCHISE_DISPATCH_URL, FRANCHISE_DISPATCH_PENDING_URL, FRANCHISE_PENDING_DELIVERY_URL, PUR_ORDER_DELIVERED_URL, PUR_INVOICE_DETAILS_URL, ORDER_CANCELLED_URL, E_INVOICE_DETAILS_URL, E_INVOICE_CANCEL_URL, E_WAY_BILL_URL, RETURN_AND_REPLACE_REPORT_LIST, ERP_API_FAILURES_LIST, FBA_ORDER_LIST, RE_ASSIGN_WAREHOUSE_LIST, ACCOUNT_LEDGER_REPORT, DEBIT_CREDIT_NOTES, DEBIT_CREDIT_NOTE_REPORT, PAYMENT_APPROVAL, GST_DATA_REPORT, ERP_OUTSTANDING_REPORT, SALES_PERSON_PERFORMANCE_URL, INCENTIVE_SLAB_URL } from "../Shared/constant";
import { useSelector, useDispatch } from "react-redux";
import { getSearchModulesObjects, SelectMainNavigation } from "../../redux/actions/adminActions";
import { useLocation } from "react-router-dom";

const CommonSearchNavigationsButton = () => {
    const [ search, setSearch ] = useState("");
    const [ showMore, setShowMore ] = useState(8);
    const [ dropdownOpen, setDropdownOpen ] = useState(false);
    const { SearchModulesObj, selfInfo } = useSelector((state) => state.adminReducers);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const Location = useLocation();
    const inputRef = useRef(null);

    const NavigationsLinks = [
        {
            module_name: "Product Applications",
            parent_module_name: "Product Masters",
            navigate_url: PRODUCT_APPLICATIONS_URL,
        },
        {
            module_name: "Category",
            parent_module_name: "Product Masters",
            navigate_url: CATEGORY_URL,
        },
        {
            module_name: "Sub Category",
            parent_module_name: "Product Masters",
            navigate_url: SUB_CATEGORY_URL
        },
        {
            module_name: "Product Group",
            parent_module_name: "Product Masters",
            navigate_url: PRODUCT_GROUP_URL
        },
        {
            module_name: "Product Creation",
            parent_module_name: "Product Masters",
            navigate_url: PRODUCT_CREATION_URL
        },
        {
            module_name: "Stock Inward",
            parent_module_name: "Stores",
            navigate_url: STOCK_INWARD_URL
        },
        {
            module_name: "Stock Level",
            parent_module_name: "Stores",
            navigate_url: STOCK_LEVEL_URL
        },
        {
            module_name: "Stock Details",
            parent_module_name: "Stores",
            navigate_url: STORE_DETAILS_URL
        },
        {
            module_name: "Stock Inward Log",
            parent_module_name: "Stores",
            navigate_url: STORE_INWARD_LOG_URL
        },
        {
            module_name: "stock details report",
            parent_module_name: "Stores",
            navigate_url: STORE_DETAILS_REPORT_URL
        },
        {
            module_name: "Warehouse",
            parent_module_name: "Warehouse",
            navigate_url: WAREHOUSE_URL
        },
        {
            module_name: "Warehouse Quick Login",
            parent_module_name: "Warehouse",
            navigate_url: WAREHOUSE_QUICK_URL
        },
        {
            module_name: "Dispatch List",
            parent_module_name: "Warehouse",
            navigate_url: DISPATCH_LIST_URL
        },
        {
            module_name: "Users",
            parent_module_name: "Admin Tools",
            navigate_url: USER_MANAGEMENT_URL
        },
        {
            module_name: "Create Franchise",
            parent_module_name: "Admin Tools",
            navigate_url: FRANCHISE_USER_REPORT_URL
        },
        {
            module_name: "Franchise Type Report",
            parent_module_name: "Admin Tools",
            navigate_url: FRANCHISE_TYPE_REPORT_URL
        },
        {
            module_name: "RM Quick Login",
            parent_module_name: "Admin Tools",
            navigate_url: RM_LOGIN_URL
        },
        {
            module_name: "States",
            parent_module_name: "Masters",
            navigate_url: STATES_URL
        },
        {
            module_name: "Cities",
            parent_module_name: "Masters",
            navigate_url: CITIES_URL
        },
        {
            module_name: "Pincodes",
            parent_module_name: "Masters",
            navigate_url: PINE_CODES_URL
        },
        {
            module_name: "Box",
            parent_module_name: "Masters",
            navigate_url: BOX_URL
        },
        {
            module_name: "Couriers",
            parent_module_name: "Masters",
            navigate_url: COURIERS_URL
        },
        {
            module_name: "Region Master",
            parent_module_name: "CRM",
            navigate_url: REGION_MASTER_URL
        },
        {
            module_name: "Purchase List",
            parent_module_name: "CRM",
            navigate_url: PURCHASE_LIST_URL
        },
        {
            module_name: "Sales Person Performance",
            parent_module_name: "CRM",
            navigate_url: SALES_PERSON_PERFORMANCE_URL
        },
        {
            module_name: "Incentive Slab",
            parent_module_name: "CRM",
            navigate_url: INCENTIVE_SLAB_URL
        },
        {
            module_name: "Resellcycle Reports",
            parent_module_name: "Re-Sale",
            navigate_url: REFURBISHED_REPORT_LIST
        },
        {
            module_name: "Resale Brands",
            parent_module_name: "Re-Sale",
            navigate_url: RESALE_BRANDS_URL
        },
        {
            module_name: "Settings",
            parent_module_name: "Re-Sale",
            navigate_url: SETTINGS_REFURBISHED_REPORT
        },
        {
            module_name: "Age Value",
            parent_module_name: "Re-Sale",
            navigate_url: AGE_VALUE_REFURBISHED_REPORT
        },
        {
            module_name: "Point List",
            parent_module_name: "Re-Sale",
            navigate_url: POINT_VALUE_REFURBISHED_REPORT
        },
        {
            module_name: "All Orders Report",
            parent_module_name: "Order Management",
            navigate_url: FRANCHISE_ORDERSREPORT_URL
        },
        {
            module_name: "SO Creation",
            parent_module_name: "Order Management",
            navigate_url: PURCHASE_ORDER_URL
        },
        {
            module_name: "SO Approvals",
            parent_module_name: "Order Management",
            navigate_url: PO_POSTING_URL
        },
        {
            module_name: "Warehouse Approvals",
            parent_module_name: "Order Management",
            navigate_url: PO_APPROVALS_URL
        },
        {
            module_name: "Pending For Stores",
            parent_module_name: "Order Management",
            navigate_url: FRANCHISE_DISPATCH_URL
        },
        {
            module_name: "Pending For Dispatch",
            parent_module_name: "Order Management",
            navigate_url: FRANCHISE_DISPATCH_PENDING_URL
        },
        {
            module_name: "Pending For Delivery",
            parent_module_name: "Order Management",
            navigate_url: FRANCHISE_PENDING_DELIVERY_URL
        },
        {
            module_name: "Order Delivered",
            parent_module_name: "Order Management",
            navigate_url: PUR_ORDER_DELIVERED_URL
        },
        {
            module_name: "Order Rejected",
            parent_module_name: "Order Management",
            navigate_url: PUR_INVOICE_DETAILS_URL
        },
        {
            module_name: "Order Cancelled",
            parent_module_name: "Order Management",
            navigate_url: ORDER_CANCELLED_URL
        },
        {
            module_name: "E-Invoice Details",
            parent_module_name: "Order Management",
            navigate_url: E_INVOICE_DETAILS_URL
        },
        {
            module_name: "E-Invoice Cancel",
            parent_module_name: "Order Management",
            navigate_url: E_INVOICE_CANCEL_URL
        },
        {
            module_name: "E-Way Bill Report",
            parent_module_name: "Order Management",
            navigate_url: E_WAY_BILL_URL
        },
        {
            module_name: "Return And Replace List",
            parent_module_name: "Order Management",
            navigate_url: RETURN_AND_REPLACE_REPORT_LIST
        },
        {
            module_name: "Erp Api Failures",
            parent_module_name: "Order Management",
            navigate_url: ERP_API_FAILURES_LIST
        },
        {
            module_name: "FBA Orders",
            parent_module_name: "Order Management",
            navigate_url: FBA_ORDER_LIST
        },
        {
            module_name: "Re Assign warehouse",
            parent_module_name: "Order Management",
            navigate_url: RE_ASSIGN_WAREHOUSE_LIST
        },
        {
            module_name: "Account Ledger Report",
            parent_module_name: "Payment Manager",
            navigate_url: ACCOUNT_LEDGER_REPORT
        },
        {
            module_name: "Debit Credit Notes",
            parent_module_name: "Payment Manager",
            navigate_url: DEBIT_CREDIT_NOTES
        },
        {
            module_name: "Debit Credit Notes Report",
            parent_module_name: "Payment Manager",
            navigate_url: DEBIT_CREDIT_NOTE_REPORT
        },
        {
            module_name: "Payment Approval",
            parent_module_name: "Payment Manager",
            navigate_url: PAYMENT_APPROVAL
        },
        {
            module_name: "GST Data Report",
            parent_module_name: "Payment Manager",
            navigate_url: GST_DATA_REPORT
        },
        {
            module_name: "ERP Outstanding Report",
            parent_module_name: "Payment Manager",
            navigate_url: ERP_OUTSTANDING_REPORT
        },
    ];

    // search bar field
    const onChangeSearch = (e) => {
        setSearch(e.target.value);
        Dispatch(getSearchModulesObjects(e.target.value));
        setDropdownOpen(e.target.value !== "");
    };

    // select navigations
    const SelectNavigations = (data) => {
        Navigate(data?.navigate_url);
        Dispatch(getSearchModulesObjects(data?.module_name));
        setSearch("");
        setDropdownOpen(false);

        if(data?.parent_module_name === "Product Masters" || data?.parent_module_name === "Stores" || data?.parent_module_name === "Warehouse") {
            Dispatch(SelectMainNavigation("Inventory"));
        } else if(data?.parent_module_name === "Admin Tools" || data?.parent_module_name === "Masters") {
            Dispatch(SelectMainNavigation("Admin-Tools"));
        } else if(data?.parent_module_name === "Order Management" || data?.parent_module_name === "Payment Manager") {
            Dispatch(SelectMainNavigation("Franchise"));
        } else if(data?.parent_module_name === "CRM" || data?.parent_module_name === "Re-Sale") {
            Dispatch(SelectMainNavigation("CRM-Tools"));
        };
    };

    useEffect(() => {
        setSearch("");
        setDropdownOpen(false);
    }, [ Location ]);

    const NavigationsLinksWithPermissions = NavigationsLinks?.filter((elm) => {
        if(selfInfo?.user?.user_type !== "super_admin") {
            const CurrentPermissions = Object.assign({}, selfInfo?.user?.permissions_access);

            if (elm?.parent_module_name === "Product Masters") {
                const matchingModules = CurrentPermissions?.ProductMasters?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Stores") {
                const matchingModules = CurrentPermissions?.Store?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Warehouse") {
                const matchingModules = CurrentPermissions?.Warehouse?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Admin Tools") {
                const matchingModules = CurrentPermissions?.AdminTools?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Masters") {
                const matchingModules = CurrentPermissions?.Masters?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "CRM") {
                const matchingModules = CurrentPermissions?.CRMTools?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Re-Sale") {
                const matchingModules = CurrentPermissions?.ReSells?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Order Management") {
                const matchingModules = CurrentPermissions?.OrderManagement?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            } else if (elm?.parent_module_name === "Payment Manager") {
                const matchingModules = CurrentPermissions?.PaymentManager?.filter(modulesz => 
                    modulesz?.label?.toLowerCase() === elm?.module_name?.toLowerCase()
                );
                return matchingModules[0]?.View === true;
            };
    
            return false;
        }
    });

    // navigations modules filter array
    const AllPermissions = selfInfo?.user?.user_type === "super_admin" ? NavigationsLinks : NavigationsLinksWithPermissions;
    const NavigationsFilter = AllPermissions?.filter((elms) => {
        if(search !== "") {
            if (
                elms?.module_name?.toLowerCase().includes(
                    search && search.toLowerCase(),
                )
            ) {
                return elms;
            };
        };
    });

    // clear search
    const ClearSearch = () => {
        Dispatch(getSearchModulesObjects(""));
        setSearch("");
        setDropdownOpen(false);
    };

    // view more function
    const ViewMoreFunction = () => {
        if(showMore === 8) {
            setShowMore(NavigationsFilter?.length)
        } else {
            setShowMore(8)
        };
    };

    // Function to handle keypress
    const handleKeyPress = (e) => {
        if (e.ctrlKey && e.key === 'p') {
        e.preventDefault(); // Prevent default browser action (if needed)
        inputRef.current.focus(); // Focus on the input box
        inputRef.current.select(); // Focus on the input box
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);

        // Cleanup the event listener when the component is unmounted
        return () => {
        window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return(<div className="search_Navigations_wrapper">
        <Dropdown 
            show={dropdownOpen && search} 
            onToggle={(isOpen, event, metadata) => {
                setDropdownOpen(isOpen);
            }}
        >
            <Dropdown.Toggle id="dropdown-basic">
                <i className="bi bi-search"></i>
                <input 
                    type="text" 
                    ref={inputRef}
                    className="form-control"
                    value={search || SearchModulesObj}
                    onChange={(e) => onChangeSearch(e)}
                    placeholder="Search or type a cammand (Ctrl + P)"
                />
                {(search !== "" || SearchModulesObj !== "") && (<i className="bi bi-x-circle-fill" onClick={() => ClearSearch()}></i>)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {NavigationsFilter && NavigationsFilter?.length > 0 ? NavigationsFilter?.slice(0, showMore)?.map((elm, index) => {
                    return(<Dropdown.Item key={index} onClick={() => SelectNavigations(elm)}>
                        <div>{elm?.module_name}</div>
                        <small>{elm?.parent_module_name}</small>
                        <i className="bi bi-bicycle"></i>
                    </Dropdown.Item>)
                }) : (<Dropdown.Item className="module_not_found">
                    Module not found
                </Dropdown.Item>)}
                {NavigationsFilter?.length > 8 && (<Button id="viewmore_btn" onClick={() => ViewMoreFunction()}>{showMore === 8 ? "View More" : "View Less"}</Button>)}
            </Dropdown.Menu>
        </Dropdown>
    </div>)
}

export default CommonSearchNavigationsButton;
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Badge } from 'react-bootstrap';
import DataTableFilter from "../../Common/DataTableFilter";
import AvatarSet from "../../Common/AvatarSet";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate, Link } from "react-router-dom";
import { CREATE_CATEGORY_URL } from "../../Shared/constant";
import { WebSocketContext } from "../../../App";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../../Common/CommonPagination";
import moment from "moment";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";

const Category = (props) => {
    const { RouteName } = props;
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { categoryList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    
    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // get brand all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[1]?.Edit || PermissionsAccess?.ProductMasters[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "brand_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[1]?.Edit || PermissionsAccess?.ProductMasters[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "category_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim()
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // create applications
    const cmnFnct = () => {
        Navigate(CREATE_CATEGORY_URL);
    };

    // get brand name
    const GetBrandName = (brand_id) => {
        return categoryList?.brands?.filter((elm) => brand_id === elm?.id)[0]?.brand_name;
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"Category"}
            searchType={"Category name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[1]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Category",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: categoryList?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered hover >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Category Name</th>
                            <th>Brand Name</th>
                            <th>Added on</th>
                            <th>Image</th>
                            <th>Status</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ProductMasters[1]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {categoryList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, categoryList?.pagination?.current_page, categoryList?.pagination?.record_limit)}
                                </td>
                                <td>{elm?.category_name}</td>
                                <td>{GetBrandName(elm?.brand_id)}</td>
                                <td>{moment(elm.created_at).format('LL')}</td>
                                <td>
                                    <AvatarSet
                                        type={"product"}
                                        url={elm?.view_thumbnail_url}
                                        alt={"tableimg"}
                                    />
                                </td>
                                <td>
                                    {elm?.is_active ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                                </td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ProductMasters[1]?.Edit) && (<td>
                                    <Link className="btn edittable" to={`/edit-category/${elm?.id}`}>
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>
                                </td>)}
                            </tr>)
                        })}
                        {categoryList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="7">Category list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {categoryList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={categoryList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {/* {showModalNew?.open && (<CommonewModel setCurrentPage={setCurrentPage} setShowModalNew={setShowModalNew} showModalNew={showModalNew}/>)} */}
    </section>)
}

export default Category;
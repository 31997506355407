/* eslint-disable */
import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Table, Form, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import { Link, useParams, useNavigate  } from "react-router-dom";
import { FRANCHISE_DISPATCH_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import CheckValid from "../../Common/CheckValid";
import SelectReact from "../../Common/SelectReact";
import CancelOrder from "../../Common/CancelOrder";
import { GetGSTAmount } from "../../Common/TimeSense";
import CommonewModel from "../../Common/CommonewModel";
import UploadFileAPI from "../../../Api/UploadFileAPI";
import CommonLoader from "../../Common/CommonLoader";
import { StartMainCommonLoader, GetOrderDetailByOrderCode } from "../../../redux/actions/adminActions";
import BrowserUniqueTabID from "../../Common/BrowserUniqueTabID";

const ReAssignWarehouseOrders = (props) => {
    const { selfInfo, commonMainLoader } = useSelector((state) => state.adminReducers);
    const divRef = useRef(null);
    const { RouteName } = props;
    const { roomId } = useParams();
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, ClearFormSet, OrderDetailsByCode, wareHouselistall, AllDispacchList, accessToken, ErrorMessageFromReddux } = useSelector((state) => state.adminReducers);
    const getDispatchOption = () =>{
        return AllDispacchList?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({
            ...item,
            key:item.id, value:item?.id , label: item?.dispatch_name
        }))
    }
    const getWareHouselOption = () =>{
        return wareHouselistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({
            key:item.id, value:item?.id , label:`${item?.warehouse_name} (${item?.warehouse_code}) - (${item?.display_name})`
        }));
    }
    const optionsWarehouse =  useMemo(getWareHouselOption, [wareHouselistall]);
    const optionsDispatch = useMemo(getDispatchOption, [AllDispacchList]);
    const [ selectWarehouse, setselectWarehouse ] = useState("");
    const [ selectDispatch, setselectDispatch ] = useState("");
    const [ OrderPriority, setOrderPriority ] = useState("");

    const [ Address, setAddress ] = useState("");
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // error state
    const [ errorAddress, seterrorAddress ] = useState("");
    const [ errorSelectWarehouse, setErrorSelectWarehouse ] = useState("");
    const [ errorSelectDispatch, setErrorSelectDispatch ] = useState("");
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ SearchInvoiceID, setSearchInvoiceID ] = useState("");
    const [ warehouseDivHight, setwarehouseDivHight ] = useState("");

    var tabID = BrowserUniqueTabID();

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // get current warehouse assign div height
    useEffect(() => {
        if (divRef.current) {
            const height = divRef.current.clientHeight;
            setwarehouseDivHight(height);
        }
    }, [ divRef ]);

    const callDispatchList = (warehouse_id) =>{
        if(websocket){
            let param = { 
                "transmit": "broadcast", 
                "url": "dispatch_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            if(warehouse_id){
                param.request.warehouse_id = warehouse_id;
            }
            wsSend_request(websocket, param);
        }
    }
    // get all warehouse list
    // useEffect(() => {
    //     callDispatchList();
    // }, [websocket]);

    // get all warehouse list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[17]?.Edit || PermissionsAccess?.OrderManagement[17]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "warehouse_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [websocket]);

    useEffect(() => {
        if(Object?.keys(OrderDetailsByCode)?.length > 0) {
            setOrderPriority((OrderDetailsByCode?.order?.order_priority === null || OrderDetailsByCode?.order?.order_priority === "") ? "" : OrderDetailsByCode?.order?.order_priority);
            setSearchInvoiceID(OrderDetailsByCode?.order?.order_code);
        }
    }, [ OrderDetailsByCode ]);

    // wareehouse select
    const WareHouseSelect = (e) => {
        const value = e.target.value
        if(value !== null) {
            callDispatchList(value)
            setTimeout(()=>{
                setselectWarehouse(value);
                setselectDispatch("");
                CheckValid(value, {type: 'Select Warehouse', error: setErrorSelectWarehouse});
            }, 400)
        } else {
            setselectWarehouse("");
            setselectDispatch("");
            CheckValid(value === null ? "" : value, {type: 'Select Warehouse', error: setErrorSelectWarehouse});
        }
    };

    // dispatch select
    const DispatchSelect = (e) => {
        const value = e.target.value
        if(value !== null) {
            setselectDispatch(value);
            CheckValid(value, {type: 'Select Dispatch', error: setErrorSelectDispatch});
        } else {
            setselectDispatch("");
            CheckValid(value === null ? "" : value, {type: 'Select Dispatch', error: setErrorSelectDispatch});
        }
    };
    
    const [ stateFormData, setStateFormData ] = useState([]);

    let fileUplaoadArrya = [...stateFormData];
    // upload attachments
    const UploadImage = async (e) => {
        const files = e.target.files[0];
        if(files){
            const formData = new FormData();
            formData.append("file", files, files.name);
            const fileresponse = await UploadFileAPI(accessToken, formData);
            if(fileresponse?.status === 200) {
                const FileObj = {
                    attachment_id: fileresponse?.id,
                    attachment_name: fileresponse?.name,
                }
                fileUplaoadArrya?.push(FileObj);
                setStateFormData(fileUplaoadArrya);
                document.getElementById('fileInput').value = null;
            } else {
                // setUploadFileLoader(false);
            };
        }else{
            // setFormData((data)=>({
            //     ...data,
            //     [e.target.name]:"",
            //     fileName: "",
            // }))
        }
    };

    // delet file
    const deleteFile = (id) => {
        const UpdatefileUplaoadArrya = [];
        fileUplaoadArrya?.map((elm) => {
            if(elm?.attachment_id !== id) {
                UpdatefileUplaoadArrya?.push(elm);
            }
        });
        setStateFormData(UpdatefileUplaoadArrya);
    };

    const AdminOrderApproval = (e) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Are you sure?",
            subtitle: "Order Warehouse Resign Approve",
            description: `Do you really want to reassign the warehouse for this order?</h5>`,
            modalType: "not form",
            button: "Yes",
            Data:{order_id: OrderDetailsByCode?.order?.id,warehouse_id:selectWarehouse,warehouse_remark:Address,dispatch_id:selectDispatch,attachments:stateFormData,order_priority: OrderPriority}
        });
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Navigate(FRANCHISE_DISPATCH_URL);
        };
    }, [ ClearFormSet ]);

    

    const AdminSubmitVisible = (OrderDetailsByCode?.order?.order_type === "AO" && (OrderDetailsByCode?.order?.order_source === "AMAZON_IN_API" || OrderDetailsByCode?.order?.order_source === "AMAZON")) ? (!Address || !selectWarehouse || !selectDispatch || !OrderPriority) : (!Address || !selectWarehouse || !selectDispatch);

    const SearchInvoiceFunction = (e) => {
        setSearchInvoiceID(e.target.value);
        if(e.target.value === "") {
            ClearInvoiceID();
        };
    };

    // search invoice id
    const onEnterInvoiceSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            SearchOrderList();
        };
    };

    // search order api
    const SearchOrderList = () => {
        if(SearchInvoiceID?.trim() !== "") {
            Dispatch(StartMainCommonLoader({
                url: "order_detail_warehouse_resign",
                loader: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "order_detail",
                "request": { 
                    "order_code" : SearchInvoiceID?.trim(),
                    "order_by" : "order_code"
                },
                "DeviceId": device_id,
                "TabId": tabID,
                "order_details_type": "warehouse_approval"
            };
            wsSend_request(websocket, param);
        } else {
            Dispatch(StartMainCommonLoader({
                url: "order_detail_warehouse_resign",
                loader: false
            }));
        };
    };

    // clear invoice id
    const ClearInvoiceID = () => {
        setSearchInvoiceID("");
        Dispatch(GetOrderDetailByOrderCode({}));
        setselectWarehouse("");
        setselectDispatch("");
        setOrderPriority("");
        setAddress("");
        setShowModalNew({
            ...showModalNew,
            open: false,
            title: "",
            modalType: "",
            Data: {}
        });
    };

    return(<section className="Main_section">
        <div className="filtr_Form Purchase_Order_Mendetory" style={{ borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="mb-3" >
                            <div className="serachBoxWIth serachBoxWIthNewMain">
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 100 }}
                                    overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                    Search by either hitting the enter button or clicking on the search icon.
                                </Tooltip>}
                                >
                                    <small className="filedinfomsg"><i className="bi bi-info-circle-fill"></i></small>
                                </OverlayTrigger>
                                <Form.Control 
                                    type="text" 
                                    value={SearchInvoiceID} 
                                    onChange={(e) => SearchInvoiceFunction(e)}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e);
                                        onEnterInvoiceSearch(e)
                                    }}
                                    disabled={(commonMainLoader?.url === "order_detail_warehouse_resign" && commonMainLoader?.loader)}
                                    placeholder="Search Order ID" 
                                />
                                <Button 
                                    className="searchbtn" 
                                    onClick={() => SearchOrderList()}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                        <path d="M21 21l-6 -6"></path>
                                    </svg>
                                </Button>
                                {SearchInvoiceID !== "" && (<div className="pincode_error" onClick={() => ClearInvoiceID()}>
                                    <i className="bi bi-x-circle-fill"  style={{ color: "red" }}></i>
                                </div>)}
                                {(commonMainLoader?.url === "order_detail_warehouse_resign" && commonMainLoader?.loader) && (<Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>)}
                            </div>
                            {ErrorMessageFromReddux?.Error !== "" && (<span className="error_medotory">{ErrorMessageFromReddux?.Error}</span>)}
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div>
        {OrderDetailsByCode !== undefined && Object.keys(OrderDetailsByCode)?.length > 0 ? (<Scrollbars 
                style={{ height: "calc(100vh - 149px)"}} 
                renderView={props => <div {...props} className="view"/>}
                
                className="ScrollbarsSidebar"
            >
                {(OrderDetailsByCode?.order?.order_status === "AC_APPROVED,WH_APPROVED" || OrderDetailsByCode?.order?.order_status === "AC_APPROVED" || OrderDetailsByCode?.order?.order_status === "WH_APPROVED") ? (<div className="Bigform_custom_scroll" style={{ height: "calc(100vh - 149px)" }} >
                    <div className="filtr_Form"  style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
                        <div className="formloginset formloginsetStaticData">
                            <div className="row">
                                <div className="col-md-3">
                                    <Form.Group className="mb-3 ">
                                        <Form.Label>Order No.</Form.Label>
                                        <div className="defaultvalueField">
                                            {OrderDetailsByCode?.order?.order_code}
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-3" >
                                    <Form.Group className="mb-3 ">
                                        <Form.Label>Order Date</Form.Label>
                                        <div className="defaultvalueField">
                                            {CommonDateTime(OrderDetailsByCode?.order?.created_at, "LL", "show")}
                                        </div>
                                    </Form.Group>
                                </div>
                                {/* In AO show customer_name and order id */}
                                {/* In CO show customer_name only */}
                                {(OrderDetailsByCode?.order?.order_type === "CO" || OrderDetailsByCode?.order?.order_type === "AO") && (<div className="col-md-3" >
                                    <Form.Group className="mb-3 ">
                                        <Form.Label>Customer Name</Form.Label>
                                        <div className="defaultvalueField">
                                        {OrderDetailsByCode?.order?.billing?.customer_name}
                                        </div>
                                    </Form.Group>
                                </div>)}
                                {OrderDetailsByCode?.order?.order_type === "AO" && (<div className="col-md-3" >
                                    <Form.Group className="mb-3 ">
                                        <Form.Label>Order ID</Form.Label>
                                        <div className="defaultvalueField">
                                        {OrderDetailsByCode?.order?.billing?.ecom_order_id}
                                        </div>
                                    </Form.Group>
                                </div>)}
                                {OrderDetailsByCode?.order?.order_type !== "CO" && (<div className="col-md-3" >
                                    <Form.Group className="mb-3 ">
                                        <Form.Label>Franchise Name</Form.Label>
                                        <div className="defaultvalueField">
                                        {OrderDetailsByCode?.order?.billing?.customer_name}
                                        </div>
                                    </Form.Group>
                                </div>)}
                                <div className="col-md-3" >
                                    <Form.Group className="mb-3 ">
                                        <Form.Label>Total Qty</Form.Label>
                                        <div className="defaultvalueField">
                                        {OrderDetailsByCode?.order?.qty}
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6" >
                                    <Form.Group className="mb-3 ">
                                        <Form.Label>Bill To</Form.Label>
                                        <div className="defaultvalueFieldaddress">
                                            {OrderDetailsByCode?.order?.order_type === "CO" ? (<ul>
                                                <li>Name: {OrderDetailsByCode?.order?.billing?.customer_name}</li>
                                                <li>Address: {OrderDetailsByCode?.order?.billing?.address} </li>
                                                <li>Mobile: {OrderDetailsByCode?.order?.billing?.mobile} </li>
                                                <li>City: {OrderDetailsByCode?.order?.billing_pin_data?.district?.district_name} </li>
                                                <li>PIN Code: {OrderDetailsByCode?.order?.billing_pin_data?.pin_code?.pin_code}</li>
                                                <li>GST: {OrderDetailsByCode?.order?.billing?.gst_no === null ? "-" : OrderDetailsByCode?.order?.billing?.gst_no}</li>
                                            </ul>) : (<ul>  
                                                <li>Name: {OrderDetailsByCode?.order?.billing?.customer_name}</li>
                                                <li>Address: {OrderDetailsByCode?.order?.billing?.address} </li>
                                                <li>Mobile: {OrderDetailsByCode?.order?.billing?.mobile} </li>
                                                <li>City: {OrderDetailsByCode?.order?.billing_pin_data?.district?.district_name} </li>
                                                <li>PIN Code: {OrderDetailsByCode?.order?.billing_pin_data?.pin_code?.pin_code}</li>
                                                <li>GST: {OrderDetailsByCode?.order?.billing?.gst_no}</li>
                                            </ul>)}
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6" >
                                    <Form.Group className="mb-3 ">
                                        <Form.Label>SHIP To</Form.Label>
                                        <div className="defaultvalueFieldaddress">
                                            <ul>
                                                <li>Name: {OrderDetailsByCode?.order?.customer_name}</li>
                                                <li>Address: {OrderDetailsByCode?.order?.address} </li>
                                                <li>Mobile: {OrderDetailsByCode?.order?.mobile} </li>
                                                <li>City: {OrderDetailsByCode?.order?.district?.district_name}</li>
                                                <li>PIN Code: {OrderDetailsByCode?.order?.pin_code?.pin_code}</li>
                                                <li>GST: {OrderDetailsByCode?.order?.gstin_no}</li>
                                            </ul>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableView">
                        <Table bordered hover >
                            <thead>
                                <tr>
                                    <th>Sr. no</th>
                                    <th>Product Code</th>
                                    <th>Product Name</th>
                                    <th>Unit Amount</th>
                                    <th>Tax Amount</th>
                                    <th>Quantity</th>
                                    <th>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {OrderDetailsByCode?.order_items_list?.map((elm, index) => {
                                    return(<tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{elm?.hsn_code?.replace("PC", "")}</td>
                                            <td>{elm?.product_name}</td>
                                            <td>{AmountNumberFormat(elm?.price)}</td>
                                            <td>{elm?.sub_gst}</td>
                                            <td>{elm?.qty}</td>
                                            <td>{AmountNumberFormat(elm?.sub_total)}</td>
                                        </tr>)
                                })}
                            </tbody>
                        </Table>
                        <div className="tabsWrappers" style={{ maxWidth: "50%" }}>
                            <div className="formloginset">
                                <div className="row">
                                    <div className="reassign_warehouse_wrapper" >
                                        <div className="col-md-12" >
                                            <Form.Group className="mb-3 ">
                                                <Form.Label>Old Warehouse</Form.Label>
                                                <div className="old_warehouse_details" ref={divRef}>
                                                    {OrderDetailsByCode?.order?.warehouse === undefined ? "-" : (<>
                                                        {OrderDetailsByCode?.order?.warehouse?.warehouse_name} ({OrderDetailsByCode?.order?.warehouse?.display_name})
                                                    </>)}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-12">
                                            <SelectReact
                                                onChange={WareHouseSelect}
                                                value={selectWarehouse}
                                                options={optionsWarehouse?.filter((elm) => elm?.value !== OrderDetailsByCode?.order?.warehouse?.id)}
                                                isClearable
                                                label={"Re-assign Warehouse"}
                                                className="godown-select-container"
                                                error={errorSelectWarehouse}
                                                required
                                                classNameForm={"mb-3"}
                                            />
                                        </div>
                                        <div className="border_right" style={{ height: `calc(100% - 69px - ${warehouseDivHight}px)` }}>
                                            <i className="bi bi-caret-left-fill"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <SelectReact
                                            onChange={DispatchSelect}
                                            value={selectDispatch}
                                            options={optionsDispatch}
                                            isClearable
                                            label={"Select Dispatch"}
                                            className="godown-select-container"
                                            error={errorSelectDispatch}
                                            required
                                            classNameForm={"mb-3"}
                                            disabled={selectWarehouse?false:true}
                                        />
                                    </div>
                                    {(OrderDetailsByCode?.order?.order_type === "AO" && (OrderDetailsByCode?.order?.order_source === "AMAZON_IN_API" || OrderDetailsByCode?.order?.order_source === "AMAZON")) && (<div className="col-md-12">
                                        <Form.Group className="mb-3 ">
                                            <Form.Label>Select Order Priority <span className="mendatory_star">*</span></Form.Label>
                                            <select 
                                                className="form-control" 
                                                value={OrderPriority} 
                                                onChange={(e) => setOrderPriority(e?.target?.value)}
                                            >
                                                <option value="">Select Order Priority</option>
                                                <option value="Amazon Prime">Prime </option>
                                                <option value="Amazon">Non Prime</option>
                                            </select>
                                        </Form.Group>
                                    </div>)}
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3 ">
                                            <Form.Label>Remarks <span className="mendatory_star">*</span></Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                as="textarea" rows={3}
                                                value={Address} 
                                                onChange={(e) => setAddress(e.target.value)}
                                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'Remarks', error: seterrorAddress})}
                                                onKeyDown={EmptySpaceFieldValid}
                                                placeholder="Enter Remarks(Max length 200 characters)" 
                                                maxLength={200}
                                            />
                                            {errorAddress !== "" &&<span className="error_medotory">{errorAddress}</span>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3 ">
                                            <Form.Label>Attachments</Form.Label>
                                            <label id="UploadCmnBTN" className="btn head_cmn_btn w-100 UploadCmnBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                                <i className="bi bi-upload"></i>
                                                <input type="file" name={"Attachments"} id="fileInput" onChange={(e) => UploadImage(e)}  accept=".pdf" />
                                                Upload Attachments
                                            </label>
                                            {stateFormData?.length > 0 && (<React.Fragment>
                                                <ul className="fileuploadListy">
                                                {stateFormData?.map((elm, index) => {
                                                    return(<li className="uplaodedFilename w-100" key={index}>
                                                        <i className="bi bi-file-earmark-text"></i>
                                                        {elm?.attachment_name}
                                                        <i className="bi bi-x-circle-fill" onClick={() => deleteFile(elm?.attachment_id)}></i>
                                                    </li>)
                                                })}
                                                </ul>
                                            </React.Fragment>)}
                                        </Form.Group>
                                    </div>
                                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[17]?.Edit) ? (<>
                                        <div className="col-md-12 mb-3" style={{ textAlign: "right" }}>
                                            <Button className="formbtn me-2" onClick={() => AdminOrderApproval()} id="approvlaMargin" style={{ width: "auto" }} variant="primary" disabled={AdminSubmitVisible} >
                                                Submit
                                            </Button>
                                            <CancelOrder data={OrderDetailsByCode}/>
                                        </div>
                                        <CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />
                                    </>) : (<div className="errorMessageIcon">
                                        <i className="bi bi-info-circle-fill"></i> You don't have permission to reassign the warehouse. Please contact the super admin.
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : (<span className="errorMessageIcon"><i className="bi bi-ban"></i>&nbsp; Your order should be in pending for stores stage for re-assign warehouse.</span>)}
        </Scrollbars>) : (<div className="reassign_info">
            <i className="bi bi-info-circle-fill"></i> Please enter the order code into the text field to search for order details and re-assign the warehouse.
        </div>)}

        {(commonMainLoader?.url === "wh_reassign" && commonMainLoader?.loader) && (<CommonLoader />)}
    </section>)
}

export default ReAssignWarehouseOrders;


/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Collapse, Image, Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import MoreIcon from '../../../assets/img/more_options.png';
import { useNavigate } from "react-router-dom";
import { TimeSense, getColor, getStringOfErrorValues } from "../../Common/TimeSense";
import ItemInvoiceCancel from "../../Common/ItemInvoiceCancel";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";

const OutStandingReport = (props) => {
    const navigate = useNavigate();
    const Dispatch = useDispatch();
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("all");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [activeTableDetails, setActiveTableDetails] = useState("");
    const [status, setStatus] = useState("");
    const [statusSearch, setStatusSearch] = useState("");

    const [show, setShow] = useState(null)
    const handleShow = (e) => setShow(e);
    const today = new Date();
    const { device_id, EInvoiceFailure, ClearFormSet } = useSelector((state) => state.adminReducers);
    const [OrderViewerModal, setOrderViewerModal] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });

    // SAMPLE 
    const [Data, setData] = useState([])

    useEffect(() => {
        setData([
            {
                SNo: 1,
                Franchise_Name: "TEST",
                Franchise_Category: "TEST",
                Region: "TEST",
                RM_Name: "TEST",
                Deposit_Amount: "TEST",
                Open_Balance: "TEST",
                Credit: 23,
                Debit: "TEST",
                Close_Balance: "TEST",
                Credit_Limit: "TEST",
                Outstangings: "TEST"
            }
            // Add more data objects as needed
        ]);
    }, []);
    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200) {
            callFilterData()
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        };
    }, [ClearFormSet]);

    useEffect(() => {
        if (websocket) {
            callFilterData();
        }
    }, [websocket, userSearch, userType, currentPage, userLimit]);

    const callFilterData = () => {
        let param = {
            "transmit": "broadcast",
            "url": "invoice_list",
            "request": {
                status: "CAN",
                limit: userLimit,
                page_no: currentPage,
                search: userSearch.trim(),
                ewb_status: "",
                order_by: "-updated_at",
                invoice_status: "SUCCESS",
                is_cancel: "",
                is_ewb_cancel: ""
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
        setTimeout(() => setStatusSearch(status), [1000])
    }


    const responseText = (response1) => {
        const response = JSON.parse(response1);
        let text = "";
        if (typeof (response?.errors) == "object") {
            text = text + `</p><b>errors:</b> ${getStringOfErrorValues(response?.errors)}</p>`;
        }
        if (typeof (response?.message) == "object") {
            text = text + `</p><b>message:</b> ${getStringOfErrorValues(response?.message)}</p>`;
        }
        if (typeof (response?.response) == "object") {
            text = text + `</p><b>response:</b> ${getStringOfErrorValues(response?.response)}</p>`;
        }
        if (typeof (response?.message) == "string") {
            text = text + `</p><b>message:</b> ${getStringOfErrorValues(response?.message)}</p>`;
        }
        return text;
    }

    return (<section className="Main_section">
        <div className="row">
                    <div className="col-md-3">
                        <Form.Group className="mb-3">
                            {/* <Form.Label>From</Form.Label> */}
                            <div className="datepickField">
                                <DatePicker 
                                    selected={FromDate} 
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    onChange={(date) => DateStartGet(date)} 
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth 
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={FromDate !== "" ? true : false}
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" >
                        <Form.Group className="mb-3">
                            {/* <Form.Label>To</Form.Label> */}
                            <div className="datepickField">
                                <DatePicker 
                                    selected={ToDate} 
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    minDate={FromDate}
                                    onChange={(date) => DateEndGet(date)} 
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth 
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={ToDate !== "" ? true : false}
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
        <DataTableFilter
            searchType={"Invoice ID."}
            filterType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
        />
        <div className="tableView">
            <Scrollbars
                style={{ height: "calc(100vh - 190px)" }}
                renderView={props => <div {...props} className="view" />}
                
                className="ScrollbarsSidebar"
            >
                <Table bordered hover >
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th>Franchise Name</th>
                            <th>Franchise Category</th>
                            <th>Region</th>
                            <th>RM Name</th>
                            <th>Deposit Amount</th>
                            <th>Open Balance</th>
                            <th>Credit </th>
                            <th>Debit</th>
                            <th>Close Balance</th>
                            <th>Credit Limit</th>
                            <th>Outstangings</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.SNo}</td>
                                <td>{item.Franchise_Name}</td>
                                <td>{item.Franchise_Category}</td>
                                <td>{item.Region}</td>
                                <td>{item.RM_Name}</td>
                                <td>{item.Deposit_Amount}</td>
                                <td>{item.Open_Balance}</td>
                                <td>{item.Credit}</td>
                                <td>{item.Debit}</td>
                                <td>{item.Close_Balance}</td>
                                <td>{item.Credit_Limit}</td>
                                <td>{item.Outstangings}</td>
                            </tr>
                        ))}
                        {(!EInvoiceFailure?.data || Data.length <= 0) && <tr style={{ textAlign: "center" }} ><td colSpan="10">Out-Standing Report list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {EInvoiceFailure?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={EInvoiceFailure?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        <OrderDetailsViewer
            OrderViewerModal={OrderViewerModal}
            setOrderViewerModal={setOrderViewerModal}
        />
        {show !== null && (
            <Modal show={show !== null} className="errorDetailsModal" onHide={() => setShow(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Error Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflow: "auto" }}>
                    <p dangerouslySetInnerHTML={{ __html: responseText(show?.invoice_response) }}>
                    </p>
                </Modal.Body>
            </Modal>
        )}
    </section>)
}

export default OutStandingReport;


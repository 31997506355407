/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import Logo from "../../assets/img/logo.png";
import { Link, useLocation } from "react-router-dom";
import { DASHBOARD_URL } from "./constant";
import { Dropdown, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CommonewModel from "../Common/CommonewModel";
import { useDispatch, useSelector } from "react-redux";
import { setGetMagicCode, GetOrderDetailByOrderCode } from "../../redux/actions/adminActions";
import { Image } from "react-bootstrap";
import CommonSearchNavigationsButton from "../Common/CommonSearchNavigationsButton";
import { IMPORT_SALES_TARGET_API_URL } from "./constant";
import ImportDataAPI from "../../Api/ImportDataAPI";
import CommonLogout from "../Common/CommonLogout";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import monthly_targetSample from "../../assets/file/monthly_target.xls";
import CommonSplitScreenList from "../Common/CommonSplitScreenList";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";

const Header = (props) => {
    const { sidebarWidth } = props;
    const dispatch = useDispatch();
    const PageName = useLocation()?.pathname?.split("/")[1]?.replaceAll("-", " ");
    const { selfInfo, device_id, accessToken } = useSelector((state) => state.adminReducers);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    const [ LoaderStart, setLoaderStart ] = useState(false);
    const [ splitScreenModal, setsplitScreenModal ] = useState({
        open: false,
        error: true,
        title: "",
        list: [],
    });
    const { websocket } = useContext(WebSocketContext);

    // page title get on header
    const PageTitle = () => {
        const PageNameCheck = PageName === "e invoice details" ? "e-invoice details" : PageName === "e invoice cancel" ? "e-invoice cancel" : PageName === "e way bill report" ? "e-way bill report" : PageName;
        if(Location === DASHBOARD_URL || Location === "/") {
            return PageNameCheck ;
        } else {
            return PageNameCheck;
        }
    };

     // logout function
     const LogoutFnct = (e) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Are you sure?",
            subtitle: "Logout",
            description: `Do you really want to logout ?</h5>`,
            modalType: "not form",
            button: "Yes"
        });
    };

    const callBackErrorHandle = (e) =>{
        if(window.location.origin !== "http://localhost:3000" && window.location.origin !== "http://localhost:3001"){
            dispatch(setGetMagicCode(""));
        }
    };

    useEffect(() => {
        dispatch(GetOrderDetailByOrderCode({}));
    }, [ PageTitle() ]);

    // import monthly target API
    const ImportSalesFranchiseTarget = async (e) => {
        setLoaderStart(true);
        const files = e?.target?.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await ImportDataAPI(IMPORT_SALES_TARGET_API_URL, accessToken, formData);

        if(fileresponse?.status === 200) {
            APICall();
            setLoaderStart(false);
            toast.success(fileresponse?.message);
            document.getElementById('fileInput').value = null;
        } else {
            APICall();
            setLoaderStart(false);
            document.getElementById('fileInput').value = null;
            if(fileresponse?.success) {
                showErrorToast(fileresponse);
            } else {
                showErrorToast(fileresponse);
            };
            if(fileresponse?.message === "User is not authorized.") {
                CommonLogout(accessToken, dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };

    // show error toast message
    const showErrorToast = (fileresponse) => {
        const toastId = toast.error(
            <div>
            {fileresponse?.error}{" "}Click on View to show the rejected list
            <button 
                className="btn btn-view" 
                onClick={() => ViewRejectedList(fileresponse?.emails, toastId)}>
                View
            </button>
            </div>,
            {
            autoClose: 10000, // Time in milliseconds (10000ms = 10 seconds)
            }
        );
    };

    // view Sale Franchise Person list
    const ViewRejectedList = (emails, toastId) => {
        toast.dismiss(toastId);
        setsplitScreenModal({
            ...splitScreenModal,
            open: true,
            error: true,
            title: "Rejected Sale Franchise Person List",
            list: emails,
        });
    };

    // salesperson_performance_list api calling
    const APICall = () => {
        let param = { 
            "transmit": "broadcast", 
            "url": "salesperson_performance_list",
            "request" : { 
                "limit" : 10,
                "page_no" : 1, 
                "franchise_id" : null,
                "search" : null,
                "order_by" :"-updated_at"
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    };

    return(<div className="head_section">
        <div className="navLogo" style={{ width: `${sidebarWidth + 3}px`}}>
            <Link to={DASHBOARD_URL} className="nav-logo">
                <Image src={process.env.REACT_APP_LOGO}  alt="logo"  onErrorCapture={callBackErrorHandle} onError={callBackErrorHandle} />
            </Link>
        </div>
        <h4 className="pageTitle">{PageTitle()}</h4>
        {((selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.CRMTools[2]?.Edit) && PageName === "sales person performance") && (<div className="sales_person_uplaod_downlo_wrapper">
            <label className="btn import_monthly_target" >
                <input type="file" id="fileInput" accept=".xlsx, .xls" disabled={LoaderStart} onChange={(e) => ImportSalesFranchiseTarget(e)} />
                {LoaderStart ? (<Spinner />) : (<i className="bi bi-upload"></i>)}&nbsp; Import Sale Person Target
            </label>
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>Sample File</Tooltip>}
            >
                <a href={monthly_targetSample} className="btn sales_download_file">
                    <i className="bi bi-download"></i>
                </a>
            </OverlayTrigger>
        </div>)}
        <CommonSearchNavigationsButton />
        <div className="dropdownHeader">
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                    <i className="bi bi-person-circle"></i>  
                    <div className="profilUser">
                        <h4>{selfInfo?.user?.full_name}</h4>
                        <small>{(selfInfo?.user?.user_type === 'regional_manager' || selfInfo?.user?.user_type === 'regional_sales_team_rm') ? "Regional Manager" : (selfInfo?.user?.user_type === 'regional_sales_team_asm') ? "Assistant Sales Manager" : selfInfo?.user?.user_type?.replaceAll("_", " ")}</small>
                    </div> 
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => LogoutFnct()}>Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
        <CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />

        {splitScreenModal?.open && (<CommonSplitScreenList 
            splitScreenModal={splitScreenModal} 
            setsplitScreenModal={setsplitScreenModal}
        />)}
    </div>)
}

export default Header;
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Collapse, Image } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OrderDataTableFilter from "../../Common/OrderDataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import { FRANCHISE_DISPATCH_PENDING_URL, FRANCHISE_DISPATCH_URL, MARKET_PLACE_INVOICE_API_URL } from "../../Shared/constant";
import { Link, useNavigate } from "react-router-dom";
import MoreIcon from '../../../assets/img/more_options.png';
import { TimeSense, getColor } from "../../Common/TimeSense";
import { getTableSorting, updateOrderManagementFilter, ClearFormSetFutios } from "../../../redux/actions/adminActions";
import OrderStatusModel from "../../Common/OrderStatusModel";
import CommonExport from "../../Common/CommonExport";
import Select from 'react-select';
import BrowserUniqueTabID from "../../Common/BrowserUniqueTabID";

const FranchiseDispatchShipping = (props) => {
    const navigate = useNavigate();
    const { RouteName } = props;
    const Dispatch = useDispatch();
    const [userType, setUserType] = useState("all");
    const { websocket } = useContext(WebSocketContext);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [activeTableDetails, setActiveTableDetails] = useState("");
    const today = new Date();
    const { device_id, PendingDispatchList, OrderManagmentFilter, wareHouselistall, Regionlistall, ClearFormSet, sortingfor, selfInfo } = useSelector((state) => state.adminReducers);
    const optionsWarehouse = wareHouselistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: `${item?.warehouse_name} - (${item?.warehouse_code})- (${item?.display_name})` }));
    const [OrderViewerModal, setOrderViewerModal] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    // var tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
    var tabID = BrowserUniqueTabID();
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    //order model 
    const [OrderNewModel, setOrderNewModel] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    //order status
    const showOrder = (value) => {
        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: "Order Log",
            subtitle: "",
            Data: value
        });
    };

    // page change
    const currentFunction = (page) => {
        Dispatch(updateOrderManagementFilter({
            PendingForDispatch: {
                CurrentPage: page,
            }
        }));
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        Dispatch(updateOrderManagementFilter({
            PendingForDispatch: {
                CurrentPage: 1,
            }
        }));
    };

    const selectRegionFunction = (e) => {
        Dispatch(updateOrderManagementFilter({
            PendingForDispatch: {
                SelectRegion: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    const selectDateTypeFunction = (e) => {
        Dispatch(updateOrderManagementFilter({
            PendingForDispatch: {
                SelectDateType: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    useEffect(() => {
        CommonSortingFct("")
    }, []);

    // get all warehouse list
    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "warehouse_list",
            "request": {
                "status": "",
                "limit": 5000,
                "page_no": 1,
                "search": ""
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get all region list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            Dispatch(updateOrderManagementFilter({
                PendingForDispatch: {
                    FromDate: "",
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                PendingForDispatch: {
                    FromDate: date,
                }
            }));
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        Dispatch(updateOrderManagementFilter({
            PendingForDispatch: {
                CurrentPage: 1,
            }
        }));
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(OrderManagmentFilter?.PendingForDispatch?.FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            Dispatch(updateOrderManagementFilter({
                PendingForDispatch: {
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                PendingForDispatch: {
                    ToDate: date,
                }
            }));
        }
    };

    // wareehouse select
    const WareHouseSelect = (value) => {
        if(value !== null) {
            Dispatch(updateOrderManagementFilter({
                PendingForDispatch: {
                    WarehouseName: value,
                    CurrentPage: 1,
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                PendingForDispatch: {
                    WarehouseName: "",
                    CurrentPage: 1,
                }
            }));
        };
    };

    // get order_list all
    useEffect(() => {
        if (websocket) {
            const { UserLimit, UserSearch, SelectDateType, SelectRegion, OrderSource, FromDate, ToDate, CurrentPage, WarehouseName, is_bundle_orders, is_odi_courier_pin } = OrderManagmentFilter?.PendingForDispatch;
            
            let param = {
                "transmit": "broadcast",
                "url": "order_list",
                "request": {
                    "status": "ST_APPROVED,HALF_DISPATCH",
                    "limit": UserLimit,
                    "page_no": CurrentPage,
                    "search": UserSearch.trim(),
                    "from_date": null,
                    "to_date": null,
                    "from_inv": null,
                    "to_inv": null,
                    "from_updated_date": null,
                    "to_updated_date": null,
                    "order_type": OrderSource,
                    "region_id": SelectRegion,
                    "warehouse_id": WarehouseName?.value,
                    "order_by": sortingfor,
                    "fetch_location_info": true,
                    "is_bundle_orders": is_bundle_orders,
                    "pin_code_flag": is_odi_courier_pin?.flag,
                },
                "DeviceId": device_id
            };

            if(SelectDateType === "created_at") {
                if (FromDate !== "" && ToDate !== "") {
                    param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                    param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
                }
            } else if(SelectDateType === "invoice_at") {
                if (FromDate !== "" && ToDate !== "") {
                    param.request.from_inv = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                    param.request.to_inv = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
                }
            } else {
                if (FromDate !== "" && ToDate !== "") {
                    param.request.from_updated_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                    param.request.to_updated_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
                }
            };
            
            wsSend_request(websocket, param);
        }
    }, [websocket, userType, OrderManagmentFilter?.PendingForDispatch, sortingfor]);



    // get franchise name
    const GetFranchiseName = (billing_id) => {
        const current = PendingDispatchList?.franchise?.filter((elm) => elm?.id === billing_id)[0]
        return {
            name: current?.customer_name
        };
    };

    // open order details modal
    const OrderDetailsModal = (order_code) => {
        navigate(`${FRANCHISE_DISPATCH_PENDING_URL}/${order_code}`);
    };
    const callInvoiceModal = (e, code) => {
        let param = {
            "transmit": "broadcast",
            "url": "order_detail",
            "request": {
                "order_id": code,
            },
            "DeviceId": device_id,
            "TabId": tabID,
            modal: true
        };
        wsSend_request(websocket, param);
    }
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200) {
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        };
    }, [ClearFormSet]);

    const GetRegionName = (id) => {
        if(id === null) {
            return "-";
        } else {
            const currentRegionData = PendingDispatchList?.region_list?.filter((elm) => elm?.id === id)[0];
            return currentRegionData?.region_name
        }
    };

    // Market Place Invocie
    const MarketPlaceInvocie = async (invoice_code, uni_code) => {
        window.open(MARKET_PLACE_INVOICE_API_URL + "?invoice_code=" + invoice_code + "&uni_code=" + uni_code, '_blank');
    };

    // get current state city and pincode
    const GetPinStateCity = (data) => {
        const CurrentPincode = PendingDispatchList?.pin_code?.filter((elm) => elm?.id === data?.pin_code_id)[0];
        const CurrentState = PendingDispatchList?.state?.filter((elm) => elm?.id === data?.state_id)[0];
        const CurrentCity = PendingDispatchList?.district?.filter((elm) => elm?.id === data?.district_id)[0];

        return{
            pincode: CurrentPincode?.pin_code === null ? "-" : CurrentPincode?.pin_code,
            state: CurrentState?.state_name === null ? "-" : CurrentState?.state_name,
            city: CurrentCity?.district_name === null ? "-" : CurrentCity?.district_name,
        };
    };

    // get ship from warehouse name
    const GetCurrentWarehouse = (warehouse_id) => {
        const CurrentWAREHOUSE = wareHouselistall?.filter((elm) => elm?.id === warehouse_id)[0];
        return CurrentWAREHOUSE?.display_name;
    };

    return (<section className="Main_section">
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-2" style={{ width: "14%" }}>
                        <div className="order_type_select" >
                            <small>Date Type:</small>
                            <select className="form-control activestatusselet pe-4" id="columnfullwith" value={OrderManagmentFilter?.PendingForDispatch?.SelectDateType} onChange={(e) => selectDateTypeFunction(e)}>
                                <option value="created_at">Order Date</option>
                                <option value="invoice_at">Invoice Date</option>
                                <option value="updated_at">Updated Date</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3" style={{ width: "18%" }}>
                        <Form.Group>
                            <div className="datepickField min">
                                <DatePicker
                                    selected={OrderManagmentFilter?.PendingForDispatch?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForDispatch?.FromDate)}
                                    startDate={OrderManagmentFilter?.PendingForDispatch?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForDispatch?.FromDate)}
                                    endDate={OrderManagmentFilter?.PendingForDispatch?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForDispatch?.ToDate)}
                                    onChange={(date) => DateStartGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={OrderManagmentFilter?.PendingForDispatch?.FromDate !== "" ? true : false}
                                    name="statDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "18%" }}>
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker
                                    selected={OrderManagmentFilter?.PendingForDispatch?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForDispatch?.ToDate)}
                                    startDate={OrderManagmentFilter?.PendingForDispatch?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForDispatch?.FromDate)}
                                    endDate={OrderManagmentFilter?.PendingForDispatch?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForDispatch?.ToDate)}
                                    minDate={startDate}
                                    maxDate={endDate}
                                    onChange={(date) => DateEndGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={OrderManagmentFilter?.PendingForDispatch?.ToDate !== "" ? true : false}
                                    name="ToDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "20%" }}>
                        <Form.Group>
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    value={OrderManagmentFilter?.PendingForDispatch?.WarehouseName}
                                    onChange={(e) => WareHouseSelect(e)}
                                    isClearable
                                    options={optionsWarehouse}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Warehouse"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "14%", paddingRight: "0px" }}>
                        <select className="form-control activestatusselet pe-4" id="columnfullwith" value={OrderManagmentFilter?.PendingForDispatch?.SelectRegion} onChange={(e) => selectRegionFunction(e)}>
                            <option value="">Select Region</option>
                            {Regionlistall?.map((elm, index) => {
                                return(<option value={elm?.id} key={index}>{elm?.region_name}</option>)
                            })}
                        </select>
                    </div>
                    <div className="col-md-3" style={{ width: "auto" }}>
                        <button className="btn exportbtn" disabled={OrderManagmentFilter?.PendingForDispatch?.ToDate === "" || OrderManagmentFilter?.PendingForDispatch?.FromDate === ""} style={{ width: "auto" }} onClick={() => CommonExport("order_list", { "FromDate": null, "ToDate": null, "FromInv": OrderManagmentFilter?.PendingForDispatch?.FromDate, "ToInv": OrderManagmentFilter?.PendingForDispatch?.ToDate, "status": "ST_APPROVED,HALF_DISPATCH", "region_id": OrderManagmentFilter?.PendingForDispatch?.SelectRegion , "search": OrderManagmentFilter?.PendingForDispatch?.UserSearch.trim(), "order_type": OrderManagmentFilter?.PendingForDispatch?.OrderSource })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <OrderDataTableFilter
            filterType={""}
            userType={userType}
            searchType={"Order Id, Name, Invoice ID, Customer Code"}
            setUserType={setUserType}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
        />
        <div className="tableView orderlistTable gstreporttableadded">
            <Scrollbars
                style={{ height: PendingDispatchList?.pagination?.total_records > 10 ? "calc(100vh - 245px)" : "calc(100vh - 215px)" }}
                renderView={props => <div {...props} className="view" />}
                
                className="ScrollbarsSidebar"
            >
                <Table bordered hover >
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNo</th>
                            <th className={sortingfor === "order_code" ? "active" : sortingfor === "-order_code" ? "inactive" : ""} onClick={() => CommonSortingFct("order_code")}>Order Id</th>
                            <th className={sortingfor === "customer_identity" ? "active" : sortingfor === "-customer_identity" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_identity")}>Customer Code</th>
                            <th className={sortingfor === "created_at" ? "active" : sortingfor === "-created_at" ? "inactive" : ""} onClick={() => CommonSortingFct("created_at")}>Order Date</th>
                            <th className={sortingfor === "updated_at" ? "active" : sortingfor === "-updated_at" ? "inactive" : ""} onClick={() => CommonSortingFct("updated_at")}>Updated Date</th>
                            <th className={sortingfor === "customer_name" ? "active" : sortingfor === "-customer_name" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_name")}>SHIP To Name</th>
                            <th className={sortingfor === "pin_code_id" ? "active" : sortingfor === "-pin_code_id" ? "inactive" : ""} onClick={() => CommonSortingFct("pin_code_id")}>SHIP Pincode</th>
                            <th className={sortingfor === "state_id" ? "active" : sortingfor === "-state_id" ? "inactive" : ""} onClick={() => CommonSortingFct("state_id")}>SHIP State</th>
                            <th className={sortingfor === "district_id" ? "active" : sortingfor === "-district_id" ? "inactive" : ""} onClick={() => CommonSortingFct("district_id")}>SHIP City</th>
                            <th className={sortingfor === "billing_id" ? "active" : sortingfor === "-billing_id" ? "inactive" : ""} onClick={() => CommonSortingFct("billing_id")}>BILL To Name</th>
                            <th>Old Warehouse</th>
                            <th>Ship From Warehouse</th>
                            <th className={sortingfor === "invoice_code" ? "active" : sortingfor === "-invoice_code" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_code")}>Invoice ID</th>
                            <th className={sortingfor === "invoice_at" ? "active" : sortingfor === "-invoice_at" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_at")}>Invoice Date</th>
                            <th className={sortingfor === "courier_name" ? "active" : sortingfor === "-courier_name" ? "inactive" : ""} onClick={() => CommonSortingFct("courier_name")}>Transporter</th>
                            <th className={sortingfor === "order_priority" ? "active" : sortingfor === "-order_priority" ? "inactive" : ""} onClick={() => CommonSortingFct("order_priority")}>Order Priority</th>
                            <th className={sortingfor === "priority" ? "active" : sortingfor === "-priority" ? "inactive" : ""} onClick={() => CommonSortingFct("priority")}>Criticality</th>
                            <th>Automated</th>
                            <th>ERP Order Code</th>
                            <th>ERP Api Status</th>
                            <th>ERP Invoice Status</th>
                            <th>ERP Delivery Note API status</th>
                            <th className={sortingfor === "updated_at" ? "active" : sortingfor === "-updated_at" ? "inactive" : ""} onClick={() => CommonSortingFct("updated_at")}>Pending Since </th>
                            <th>Remarks</th>
                            <th>Add. Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {PendingDispatchList?.data?.map((elm, index) => {
                            const get_color = getColor(elm);
                            return (
                                <React.Fragment key={index}>
                                    {/* <tr className={elm?.erp_bundle_id !== null ? "bundle_order_active" : ""} key={index}> */}
                                    <tr className={elm?.is_odi ? "is_odi_order_active" : elm?.is_courier_pin_code ? "is_courier_pin_code_order_active" : ""}>
                                        <td style={{ width: '10px' }}>
                                            <button
                                                className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                                onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={activeTableDetails === elm?.id ? true : false}
                                            >
                                                <Image src={MoreIcon} alt="more icon" />
                                            </button>
                                        </td>
                                        <td>
                                            <Link className="btn-link btn-link-color text-decoration-none" onClick={() => showOrder(elm?.id)}>
                                                {GetCurrentTableSRNo(index, PendingDispatchList?.pagination?.current_page, PendingDispatchList?.pagination?.record_limit)}
                                            </Link>
                                        </td>
                                        <td>
                                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[6]?.Edit) ? (
                                                <b onClick={() => OrderDetailsModal(elm?.id)} className="tableBolanchor">{elm?.order_code}</b>
                                            ) : (
                                                <b className="tableBolanchor">{elm?.order_code}</b>
                                            )}
                                        </td>
                                        <td>
                                            {elm?.customer_identity === null ? "-" : elm?.customer_identity}
                                        </td>
                                        <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                        <td>{CommonDateTime(elm?.updated_at, "LL", "show")}</td>
                                        <td>{elm?.customer_name !== null ? elm?.customer_name : "-"}</td>
                                        <td>
                                            {elm?.pin_code === null ? "-" : elm?.pin_code}
                                        </td>
                                        <td>
                                            {elm?.state === null ? "-" : elm?.state}
                                        </td>
                                        <td>
                                            {elm?.district === null ? "-" : elm?.district}
                                        </td>
                                        <td>{GetFranchiseName(elm?.billing_id)?.name === undefined ? "-" : GetFranchiseName(elm?.billing_id)?.name}</td>
                                        <td>{elm?.is_resign ? elm?.old_warehouse_name : "-"}</td>
                                        <td>{GetCurrentWarehouse(elm?.warehouse_id)}</td>
                                        <td>
                                            {elm?.invoice_code === "OPEN" ? "-" : <b className="tableBolanchor" onClick={(e) => callInvoiceModal(e, elm?.id)}>{elm?.invoice_code}</b>}
                                        </td>
                                        <td>{CommonDateTime(elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                        <td>{elm?.courier_name ? elm?.courier_name : "-"}</td>
                                        <td>{elm?.order_priority ? elm?.order_priority : "-"}</td>
                                        <td>
                                            <span className={`px-2 py-1 rounded ${get_color.color_code}`} >{get_color.text}</span>
                                        </td>
                                        <td>
                                            {elm?.is_automated ? (<Badge bg="success">Yes</Badge>) : (<Badge bg="danger">No</Badge>)}
                                        </td>
                                        <td>{(elm?.erp_id === null || elm?.erp_id === "") ? "-" : elm?.erp_id}</td>
                                        <td>
                                            {(elm?.erp_id === null || elm?.erp_id === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}
                                        </td>
                                        <td>
                                            {(elm?.erp_invoice_code === null || elm?.erp_invoice_code === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}
                                        </td>
                                        <td>{(elm?.erp_delivery_code === null || elm?.erp_delivery_code === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}</td>
                                        <td>{TimeSense(elm?.updated_at)}</td>
                                        <td>{(elm?.warehouse_remark === null || elm?.warehouse_remark === "") ? "-" : elm?.warehouse_remark}</td>
                                        <td>
                                            {elm?.old_invoice_code === null ? (<Badge bg="success">Regular Order</Badge>) : (<Badge bg="primary">Replacement Order</Badge>)}
                                        </td>
                                    </tr>
                                    <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                        <tr className="tablecollapsewraps" id={"tablecollpase" + elm?.id}>
                                            <td colSpan="12" >
                                                <Table bordered hover className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Order Source</th>
                                                            <td>{elm?.order_source === null ? "-" : elm?.order_source}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Quantity</th>
                                                            <td>{elm?.qty}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Order Value</th>
                                                            <td>{AmountNumberFormat(elm?.total)}</td>

                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>SKU Type</th>
                                                            <td>{elm?.category}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Status</th>
                                                            <td>
                                                                {elm?.order_status === "ST_APPROVED" && (<Badge bg="danger">Pending for Dispatch</Badge>)}
                                                                {elm?.order_status === "HALF_DISPATCH" && (<Badge bg="danger">Pending for Full Dispatch</Badge>)}
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <th style={{ width: "20%" }}>Invoice Date</th>
                                                            <td>{CommonDateTime(elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                                        </tr> */}
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Attachments</th>
                                                            <td>
                                                                {elm?.attachments !== null && (<ul className="fileuploadListytable">
                                                                    {elm?.attachments?.map((elm, index) => {
                                                                        return (<li key={index}>
                                                                            <a href={process.env.REACT_APP_BASE_URL + "file/" + elm?.view_file_url} download={elm?.attachment_name} target="__blank"><i className="bi bi-file-earmark-text"></i> {elm?.attachment_name}  <i className="bi bi-download downloadIcomn"></i></a>
                                                                        </li>)
                                                                    })}
                                                                </ul>)}
                                                                {(elm?.attachments !== null && PendingDispatchList?.uni_com_invoice_list?.filter((elm__) => (elm__?.order_id === elm?.id && elm?.order_type === "AO"))?.length > 0) && (<br/>)}
                                                                {PendingDispatchList?.uni_com_invoice_list?.filter((elm__) => (elm__?.order_id === elm?.id && elm?.order_type === "AO"))?.length > 0 && PendingDispatchList?.uni_com_invoice_list?.map((elm__, index) => {
                                                                    if(elm__?.order_id === elm?.id && elm?.order_type === "AO") {
                                                                        return(<React.Fragment key={index}>
                                                                            {elm__?.invoiceCode !== null && elm?.uni_code !== null && (
                                                                                <a key={index} className="anchorDownoad" onClick={() => MarketPlaceInvocie(elm__?.invoiceCode, elm?.uni_code)}><i className="bi bi-file-earmark-text"></i> Market place invoice</a>
                                                                            )}
                                                                        </React.Fragment>)
                                                                    }
                                                                })}
                                                                {(PendingDispatchList?.uni_com_invoice_list?.filter((elm__) => (elm__?.order_id === elm?.id && elm?.order_type === "AO"))?.length > 0 && elm?.uni_shipment_url !== null) && (<br/>)}
                                                                {elm?.uni_shipment_url !== null && (
                                                                    <a href={elm?.uni_shipment_url} download={elm?.uni_shipment_url} target="__blank"><i className="bi bi-file-earmark-text"></i> Shipping label</a>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Region</th>
                                                            <td>{GetRegionName(elm?.region_id)}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Collapse>
                                </React.Fragment>
                            )
                        })}
                        {PendingDispatchList?.data?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="12">Pending for dispatch list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {PendingDispatchList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={OrderManagmentFilter?.PendingForDispatch?.CurrentPage}
                paginationLength={PendingDispatchList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        <OrderDetailsViewer
            OrderViewerModal={OrderViewerModal}
            setOrderViewerModal={setOrderViewerModal}
        />
        <OrderStatusModel setOrderNewModel={setOrderNewModel} OrderNewModel={OrderNewModel} />
    </section>)
}

export default FranchiseDispatchShipping;
/* eslint-disable */
import React, { useEffect } from "react";
import { Nav, Collapse } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { CATEGORY_URL, DASHBOARD_URL, FBA_ORDER_LIST, REFURBISHED_REPORT_LIST, ERP_API_FAILURES_LIST, ADD_RESALE_BRANDS_URL, RESALE_BRANDS_URL, ADD_AGE_VALUE_REPORT, PRODUCT_APPLICATIONS_URL, AGE_VALUE_REPORT, UPDATE_RESALE_BRANDS_URL, ADD_POINT_VALUE_SETTINGS, POINT_VALUE_REFURBISHED_REPORT, SETTINGS_REFURBISHED_REPORT, SUB_CATEGORY_URL, PRODUCT_CREATION_URL,RETURN_AND_REPLACE_REPORT, ADD_POINT_VALUE_REFURBISHED_REPORT, EDIT_POINT_VALUE_REFURBISHED_REPORT, CREATE_PRODUCT_APLICATION_URL, RETURN_AND_REPLACE_REPORT_LIST, STORE_INWARD_LOG_URL, ERP_OUTSTANDING_REPORT, CREATE_CATEGORY_URL, CREATE_SUB_CATEGORY_URL, CREATE_PRODUCT_URL, RM_LOGIN_URL, FRANCHISE_USER_REPORT_URL, CREATE_FRANCHISE_USER_REPORT_URL, STOCK_INWARD_URL, STOCK_LEVEL_URL, STORE_DETAILS_URL, STORE_DETAILS_REPORT_URL, FRANCHISE_REPORT_DETAILS_URL, STOCK_TRANSFER_URL, WAREHOUSE_URL, CREATE_WAREHOUSE_URL, WAREHOUSE_QUICK_URL, FRANCHISE_ORDERSREPORT_URL, E_WAY_BILL_URL, FRANCHISE_STOCK_LEVEL_URL, E_INVOICE_CANCEL_URL, E_INVOICE_DETAILS_URL, PUR_INVOICE_DETAILS_URL, PUR_ORDER_DELIVERED_URL, FRANCHISE_PENDING_DELIVERY_URL, FRANCHISE_DISPATCH_PENDING_URL, FRANCHISE_DISPATCH_URL, FRANCHISE_PENDING_ORDERS_URL, PO_APPROVALS_URL, PO_POSTING_URL, USER_MANAGEMENT_URL, REGION_MASTER_URL, ADD_REGION_MASTER_URL, CITIES_URL, STATES_URL, ADD_CITIES_URL, ADD_STATES_URL, PINE_CODES_URL, CREATE_USER_MANAGEMENT_URL, FRANCHISE_TYPE_REPORT_URL, ADD_FRANCHISE_TYPE_REPORT_URL, PRODUCT_GROUP_URL, CREATE_PRODUCT_GROUP_URL, PURCHASE_ORDER_URL, DISPATCH_LIST_URL, CREATE_DISPATCH_LIST_URL, COURIERS_URL, ORDER_CANCELLED_URL, BOX_URL, DEBIT_CREDIT_NOTES, ACCOUNT_LEDGER_REPORT, GSTDATAREPORT, GST_DATA_REPORT, OUT_STANDING_DATA_REPORT, DEBIT_CREDIT_NOTE_REPORT, DEPOSIT_REPORT, PAYMENT_APPROVAL, CREDIT_NOTE_REQUEST, CREDIT_NOTE_REPORT, PURCHASE_LIST_URL, ADD_PINCODES_URL, FRANCHISE_USER_REPORT_BRAND, FBA_ORDER_DETAILS_LIST, RE_ASSIGN_WAREHOUSE_LIST, SALES_PERSON_PERFORMANCE_URL, INCENTIVE_SLAB_URL } from "../constant";
import { useSelector, useDispatch } from "react-redux";
import { SearchPincodeList, SearchCommonPincodeList, getSearchModulesObjects, getPointValueList, getResallBrandList, getResellcycleReportsList, getResellcycleCustomerReportsList, ErrorMessageFromRedux, GetReturnReplaceInvoiceOrderDetails, GetReturnReplaceFramesOrderDetails } from "../../../redux/actions/adminActions";

const SidebarChild = (props) => {
    const { selfInfo, MainNavUrl } = useSelector((state) => state.adminReducers);
    const { menuStates, setMenuStates } = props;
    const Dispatch = useDispatch();
    const LocationURL = useLocation().pathname;
    const LocationEditURL = LocationURL?.split("/")[1];
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // toggle menu
    const toggleMenu = (menu) => {
        const updatedMenuStates = { ...menuStates };
        Object.keys(updatedMenuStates).forEach((key) => {
            if (key === menu) {
                updatedMenuStates[key] = !updatedMenuStates[key];
            } else {
                updatedMenuStates[key] = false;
            }
        });
        setMenuStates(updatedMenuStates);
    };

    // Update Toggle
    useEffect(() => {
        if (MainNavUrl === "Inventory") {
            if (LocationURL === PRODUCT_APPLICATIONS_URL || LocationURL === CATEGORY_URL || LocationURL === SUB_CATEGORY_URL || LocationURL === PRODUCT_CREATION_URL || LocationURL === CREATE_PRODUCT_APLICATION_URL || LocationURL === CREATE_CATEGORY_URL || LocationURL === CREATE_SUB_CATEGORY_URL || LocationURL === CREATE_PRODUCT_URL || LocationURL === FRANCHISE_USER_REPORT_URL || LocationEditURL === "edit-product-application" || LocationEditURL === "edit-category" || LocationEditURL === "edit-subcategory" || LocationEditURL === "edit-product" || LocationURL === PRODUCT_GROUP_URL) {
                setMenuStates({
                    ...menuStates,
                    menu1: true,
                    menu2: false,
                    menu3: false,
                    menu4: false,
                });
            } else if (LocationURL === STOCK_INWARD_URL || LocationURL === STOCK_LEVEL_URL || LocationURL === STORE_DETAILS_URL || LocationURL === STORE_DETAILS_REPORT_URL || LocationURL === FRANCHISE_REPORT_DETAILS_URL || LocationURL === STORE_INWARD_LOG_URL) {
                setMenuStates({
                    ...menuStates,
                    menu1: false,
                    menu2: true,
                    menu3: false,
                    menu4: false,
                });
            } else if (LocationURL === STOCK_TRANSFER_URL) {
                setMenuStates({
                    ...menuStates,
                    menu1: false,
                    menu2: false,
                    menu3: true,
                    menu4: false,
                });
            } else if (LocationURL === WAREHOUSE_URL || LocationURL === CREATE_WAREHOUSE_URL || LocationEditURL === "edit-warehouse" || LocationURL === WAREHOUSE_QUICK_URL) {
                setMenuStates({
                    ...menuStates,
                    menu1: false,
                    menu2: false,
                    menu3: false,
                    menu4: true,
                });
            };
        } else if (MainNavUrl === "Admin-Tools") {
            if (LocationURL === USER_MANAGEMENT_URL || LocationURL === CREATE_USER_MANAGEMENT_URL || LocationEditURL === "edit-user" || LocationURL === FRANCHISE_USER_REPORT_URL || LocationURL === CREATE_FRANCHISE_USER_REPORT_URL || LocationEditURL === "edit-franchise-user-report" || LocationURL === FRANCHISE_TYPE_REPORT_URL || LocationURL === ADD_FRANCHISE_TYPE_REPORT_URL || LocationEditURL === "edit-franchise-type-report" || LocationURL === RM_LOGIN_URL || LocationURL === FRANCHISE_USER_REPORT_BRAND) {
                setMenuStates({
                    ...menuStates,
                    menu1: true,
                    menu2: false,
                });
            } else if (LocationURL === CITIES_URL || LocationURL === STATES_URL || LocationURL === ADD_CITIES_URL || LocationURL === ADD_STATES_URL || LocationURL === PINE_CODES_URL || LocationURL === ADD_PINCODES_URL || LocationEditURL == "edit-pincode" || LocationURL === BOX_URL || LocationURL === COURIERS_URL) {
                setMenuStates({
                    ...menuStates,
                    menu1: false,
                    menu2: true,
                });
            };
        } else if (MainNavUrl === "Franchise") {
            if (LocationURL === FRANCHISE_ORDERSREPORT_URL || LocationURL === E_WAY_BILL_URL || LocationURL === FRANCHISE_STOCK_LEVEL_URL || LocationURL === E_INVOICE_CANCEL_URL || LocationURL === E_INVOICE_DETAILS_URL || LocationURL === PUR_INVOICE_DETAILS_URL || LocationURL === PUR_ORDER_DELIVERED_URL || LocationURL === FRANCHISE_PENDING_DELIVERY_URL || LocationURL === FRANCHISE_DISPATCH_PENDING_URL || LocationURL === FRANCHISE_DISPATCH_URL || LocationURL === FRANCHISE_PENDING_ORDERS_URL || LocationURL === PO_APPROVALS_URL || LocationURL === PO_POSTING_URL || LocationURL === PURCHASE_ORDER_URL || LocationEditURL === "so-approval" || LocationEditURL === "warehouse-approval" || LocationURL === DISPATCH_LIST_URL || LocationURL === CREATE_DISPATCH_LIST_URL || LocationEditURL === "edit-dispatch" || LocationURL === ORDER_CANCELLED_URL || LocationURL === E_INVOICE_DETAILS_URL || LocationURL === E_INVOICE_CANCEL_URL || LocationURL === E_WAY_BILL_URL || LocationURL === RETURN_AND_REPLACE_REPORT || LocationURL === RETURN_AND_REPLACE_REPORT_LIST || LocationURL === ERP_API_FAILURES_LIST || LocationURL === FBA_ORDER_LIST || LocationEditURL === "fba-orders-details" || LocationURL === RE_ASSIGN_WAREHOUSE_LIST) {
                setMenuStates({
                    ...menuStates,
                    menu1: true,
                    menu2: false,
                }); 
            }else if(LocationURL === DEBIT_CREDIT_NOTES || LocationURL === ACCOUNT_LEDGER_REPORT || LocationURL=== DEBIT_CREDIT_NOTE_REPORT || LocationURL === DEPOSIT_REPORT || LocationURL === PAYMENT_APPROVAL || LocationURL === CREDIT_NOTE_REQUEST || LocationURL === CREDIT_NOTE_REPORT || LocationEditURL === DEBIT_CREDIT_NOTES || LocationURL === GST_DATA_REPORT || LocationURL === ERP_OUTSTANDING_REPORT){
                setMenuStates({
                    ...menuStates,
                    menu1: false,
                    menu2: true,
                });
            }
        } else if (MainNavUrl === "CRM-Tools") {
            if (LocationURL === REGION_MASTER_URL || LocationURL === ADD_REGION_MASTER_URL || LocationEditURL === "edit-region-master" || LocationURL === PURCHASE_LIST_URL || LocationEditURL === "edit-purchase" || LocationURL === SALES_PERSON_PERFORMANCE_URL || LocationURL === INCENTIVE_SLAB_URL) {
                setMenuStates({
                    ...menuStates,
                    menu1: true,
                    menu2: false,
                });
            } else if(LocationURL === SETTINGS_REFURBISHED_REPORT || LocationURL === POINT_VALUE_REFURBISHED_REPORT || LocationURL === ADD_POINT_VALUE_REFURBISHED_REPORT || LocationEditURL === "edit-point" || LocationURL === REFURBISHED_REPORT_LIST || LocationURL === ADD_POINT_VALUE_SETTINGS || LocationEditURL === "edit-point-settings" || LocationURL === AGE_VALUE_REPORT || LocationURL === ADD_AGE_VALUE_REPORT || LocationEditURL === "edit-age-value" || LocationURL === RESALE_BRANDS_URL || LocationURL === ADD_RESALE_BRANDS_URL || LocationEditURL === "edit-resale-brand") {
                setMenuStates({
                    ...menuStates,
                    menu1: false,
                    menu2: true,
                });
            };
        }else if (MainNavUrl === "Sales") {
            if (LocationURL === OUT_STANDING_DATA_REPORT) {
                setMenuStates({
                    ...menuStates,
                    menu3: true
                });
            };
        };
    }, [ MainNavUrl, LocationURL ]);

    // common navigations links
    const CommonNavigationsChange = () => {
        Dispatch(getSearchModulesObjects(""));
    };

    useEffect(() => {
        return () => {
            Dispatch(SearchPincodeList({}));
            Dispatch(SearchCommonPincodeList({}));
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
            Dispatch(GetReturnReplaceInvoiceOrderDetails([]));
            Dispatch(GetReturnReplaceFramesOrderDetails({}));
            
            if(LocationURL !== "/resellcycle-reports") {
                Dispatch(getResellcycleReportsList({
                    list: [],
                    refurbished_brand: [],
                    pagination: {}
                }));
                Dispatch(getResellcycleCustomerReportsList({
                    list: [],
                    refurbished_brand: [],
                    district: [],
                    pin_code: [],
                    state: [],
                    pagination: {}
                }))
            } else if(LocationURL !== "/resale-brands") {
                Dispatch(getResallBrandList({
                    list: [],
                    pagination: {}
                }))
            } else if(LocationURL !== "/point-list") {
                Dispatch(getPointValueList({
                    list: [],
                    pagination: {}
                }))
            }
        };
    }, [LocationURL]);

    return (<div className="sidebar">
        <Scrollbars
            style={{ height: "calc(100vh - 50px)" }}
            renderView={props => <div {...props} className="view" />}
        >
            {MainNavUrl === "Inventory" ? (<Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ProductMasters?.filter((elm) => elm?.API !== "")?.length > 0) &&
                (<Nav.Link className={Object.assign(menuStates)["menu1"] ? "active" : ""} onClick={() => toggleMenu('menu1')} aria-controls="menu1" aria-expanded={menuStates.menu1}>
                    <i className="bi bi-box-seam"></i>
                    Product Masters
                    <span className={Object.assign(menuStates)["menu1"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                </Nav.Link>)}
                <Collapse className="collapnseDrop" in={menuStates.menu1}>
                    <div id="menu1">
                        <ul>
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[0]?.Edit || PermissionsAccess?.ProductMasters[0]?.View)) && (<li>
                                <Link className={(LocationURL === PRODUCT_APPLICATIONS_URL || LocationURL === CREATE_PRODUCT_APLICATION_URL || LocationEditURL === "edit-product-application") ? "active" : ""} to={PRODUCT_APPLICATIONS_URL} onClick={() => CommonNavigationsChange()}>Product Applications</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[1]?.Edit || PermissionsAccess?.ProductMasters[1]?.View)) && (<li>
                                <Link className={(LocationURL === CATEGORY_URL || LocationURL === CREATE_CATEGORY_URL || LocationEditURL === "edit-category") ? "active" : ""} to={CATEGORY_URL} onClick={() => CommonNavigationsChange()}>Category</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[2]?.Edit || PermissionsAccess?.ProductMasters[2]?.View)) && (<li>
                                <Link className={(LocationURL === SUB_CATEGORY_URL || LocationURL === CREATE_SUB_CATEGORY_URL || LocationEditURL === "edit-subcategory") ? "active" : ""} to={SUB_CATEGORY_URL} onClick={() => CommonNavigationsChange()}>Sub Category</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[3]?.Edit || PermissionsAccess?.ProductMasters[3]?.View)) && (<li>
                                <Link className={(LocationURL === PRODUCT_GROUP_URL || LocationURL === CREATE_PRODUCT_GROUP_URL || LocationEditURL === "edit-product-group") ? "active" : ""} to={PRODUCT_GROUP_URL} onClick={() => CommonNavigationsChange()}>Product Group</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[4]?.Edit || PermissionsAccess?.ProductMasters[4]?.View)) && (<li>
                                <Link className={(LocationURL === PRODUCT_CREATION_URL || LocationURL === CREATE_PRODUCT_URL || LocationEditURL === "edit-product") ? "active" : ""} to={PRODUCT_CREATION_URL} onClick={() => CommonNavigationsChange()}>Product Creation</Link>
                            </li>)}
                        </ul>
                    </div>
                </Collapse>
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Store?.filter((elm) => elm?.API !== "")?.length > 0) && (<Nav.Link className={Object.assign(menuStates)["menu2"] ? "active" : ""} onClick={() => toggleMenu('menu2')} aria-controls="menu2" aria-expanded={menuStates.menu2}>
                    <i className="bi bi-shop-window"></i>
                    Stores
                    <span className={Object.assign(menuStates)["menu2"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                </Nav.Link>)}
                <Collapse className="collapnseDrop" in={menuStates.menu2}>
                    <div id="menu2">
                        <ul>
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[0]?.Edit || PermissionsAccess?.Store[0]?.View)) && (<li>
                                <NavLink className={LocationURL === STOCK_INWARD_URL ? "active" : ""} to={STOCK_INWARD_URL} onClick={() => CommonNavigationsChange()}>Stock Inward</NavLink>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[1]?.Edit || PermissionsAccess?.Store[1]?.View)) && (<li>
                                    <Link className={LocationURL === STOCK_LEVEL_URL ? "active" : ""} to={STOCK_LEVEL_URL} onClick={() => CommonNavigationsChange()}>Stock Level</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[2]?.Edit || PermissionsAccess?.Store[2]?.View)) && (<li>
                                    <Link className={LocationURL === STORE_DETAILS_URL ? "active" : ""} to={STORE_DETAILS_URL} onClick={() => CommonNavigationsChange()}>Stock Details</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[3]?.Edit || PermissionsAccess?.Store[3]?.View)) && (<li>
                                <Link className={LocationURL === STORE_INWARD_LOG_URL ? "active" : ""} to={STORE_INWARD_LOG_URL} onClick={() => CommonNavigationsChange()}>Stock Inward Log</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[4]?.Edit || PermissionsAccess?.Store[4]?.View)) && (<li>
                                <Link className={LocationURL === STORE_DETAILS_REPORT_URL ? "active" : ""} to={STORE_DETAILS_REPORT_URL} onClick={() => CommonNavigationsChange()}>Stock Details Report</Link>
                            </li>)}
                            {/* {(selfInfo?.user?.user_type === 'super_admin' &&
                                <li>
                                    <Link className={LocationURL === FRANCHISE_REPORT_DETAILS_URL ? "active" : ""} to={FRANCHISE_REPORT_DETAILS_URL}>Franchise Store Details Report</Link>
                                </li>)} */}
                        </ul>
                    </div>
                </Collapse>
                {/* {(selfInfo?.user?.user_type === 'super_admin' &&
                    <Nav.Link className={Object.assign(menuStates)["menu3"] ? "active" : ""} onClick={() => toggleMenu('menu3')} aria-controls="menu3" aria-expanded={menuStates.menu3}>
                        <i className="bi bi-arrow-left-right"></i>
                        Stock Transfer
                        <span className={Object.assign(menuStates)["menu3"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                    </Nav.Link>)}
                <Collapse className="collapnseDrop" in={menuStates.menu3}>
                    <div id="menu3">
                        <ul>
                            <li>
                                <Link className={LocationURL === STOCK_TRANSFER_URL ? "active" : ""} to={STOCK_TRANSFER_URL}>Stock Transfer</Link>
                            </li>
                        </ul>
                    </div>
                </Collapse> */}
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Warehouse?.filter((elm) => elm?.API !== "")?.length > 0) && (<Nav.Link className={Object.assign(menuStates)["menu4"] ? "active" : ""} onClick={() => toggleMenu('menu4')} aria-controls="menu4" aria-expanded={menuStates.menu4}>
                    <i className="bi bi-houses"></i>
                    Warehouse
                    <span className={Object.assign(menuStates)["menu4"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                </Nav.Link>)}
                <Collapse className="collapnseDrop" in={menuStates.menu4}>
                    <div id="menu4">
                        <ul>
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[0]?.Edit || PermissionsAccess?.Warehouse[0]?.View)) && (<li>
                                <Link className={(LocationURL === WAREHOUSE_URL || LocationURL === CREATE_WAREHOUSE_URL || LocationEditURL === "edit-warehouse") ? "active" : ""} to={WAREHOUSE_URL} onClick={() => CommonNavigationsChange()}>Warehouse</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[1]?.Edit || PermissionsAccess?.Warehouse[1]?.View)) && (<li>
                                <Link className={LocationURL === WAREHOUSE_QUICK_URL ? "active" : ""} to={WAREHOUSE_QUICK_URL} onClick={() => CommonNavigationsChange()}>Warehouse Quick Login</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[2]?.Edit || PermissionsAccess?.Warehouse[2]?.View)) && (<li>
                                <Link className={(LocationURL === DISPATCH_LIST_URL || LocationURL === CREATE_DISPATCH_LIST_URL || LocationEditURL === "edit-dispatch") ? "active" : ""} to={DISPATCH_LIST_URL} onClick={() => CommonNavigationsChange()}>Dispatch List</Link>
                            </li>)}
                        </ul>
                    </div>
                </Collapse>
            </Nav>) : MainNavUrl === "Sales" ? (<Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                <Nav.Link className={Object.assign(menuStates)["menu1"] ? "active" : ""} onClick={() => toggleMenu('menu1')} aria-controls="menu1" aria-expanded={menuStates.menu1}>
                    <i className="bi bi-tags"></i>
                    Sales Manager
                    <span className={Object.assign(menuStates)["menu1"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                </Nav.Link>
                <Collapse className="collapnseDrop" in={menuStates.menu1}>
                    <div id="menu1">
                        <ul>
                            <li><a href="#">All Orders Report</a></li>
                            <li><a href="#">Pending For Accounts</a></li>
                            <li><a href="#">Pending For Stores</a></li>
                            <li><a href="#">Pending For Dispatch</a></li>
                            <li><a href="#">Pending For Delivery</a></li>
                            <li><a href="#">Delivered</a></li>
                            <li><a href="#">Rejected</a></li>
                            <li><a href="#">EInvoiceOnline Details</a></li>
                            <li><a href="#">E-Invoice Cancel</a></li>
                            <li><a href="#">Other Payments Report</a></li>
                        </ul>
                    </div>
                </Collapse>
                <Nav.Link className={Object.assign(menuStates)["menu2"] ? "active" : ""} onClick={() => toggleMenu('menu2')} aria-controls="menu2" aria-expanded={menuStates.menu2}>
                    <i className="bi bi-shop-window"></i>
                    MasterShoppee
                    <span className={Object.assign(menuStates)["menu2"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                </Nav.Link>
                <Collapse className="collapnseDrop" in={menuStates.menu2}>
                    <div id="menu2">
                        <ul>
                            <li><a href="#">Shop Now</a></li>
                        </ul>
                    </div>
                </Collapse>
                <Nav.Link className={Object.assign(menuStates)["menu3"] ? "active" : ""} onClick={() => toggleMenu('menu3')} aria-controls="menu3" aria-expanded={menuStates.menu3}>
                    <i className="bi bi-flag"></i>
                    Reports
                    <span className={Object.assign(menuStates)["menu3"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                </Nav.Link>
                <Collapse className="collapnseDrop" in={menuStates.menu3}>
                    <div id="menu3">
                        <ul>
                            <li><Link className={LocationURL === OUT_STANDING_DATA_REPORT ? "active" : ""} to={OUT_STANDING_DATA_REPORT} onClick={() => CommonNavigationsChange()}>Out-Standing Report</Link></li>
                            <li><a href="#">QR Purchase Report</a></li>
                            <li><a href="#">DSR Monthly Report</a></li>
                            <li><a href="#">DSR Product Wise Report</a></li>
                            <li><a href="#">Update QR Purchase</a></li>
                            <li><a href="#">Frame Number Update</a></li>
                        </ul>
                    </div>
                </Collapse>
            </Nav>) : MainNavUrl === "Admin-Tools" ? (<Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools?.filter((elm) => elm?.API !== "")?.length > 0) && (<Nav.Link className={Object.assign(menuStates)["menu1"] ? "active" : ""} onClick={() => toggleMenu('menu1')} aria-controls="menu1" aria-expanded={menuStates.menu1}>
                    <i className="bi bi-person-fill-gear"></i>
                    Admin Tools
                    <span className={Object.assign(menuStates)["menu1"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                </Nav.Link>)}
                <Collapse className="collapnseDrop" in={menuStates.menu1}>
                    <div id="menu1">
                        <ul>
                            <li>
                                {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[0]?.Edit || PermissionsAccess?.AdminTools[0]?.View)) && (<li>
                                    <Link className={(LocationURL === USER_MANAGEMENT_URL || LocationURL === CREATE_USER_MANAGEMENT_URL || LocationEditURL === "edit-user") ? "active" : ""} to={USER_MANAGEMENT_URL} onClick={() => CommonNavigationsChange()}>Users</Link>
                                </li>)}
                                {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[1]?.Edit || PermissionsAccess?.AdminTools[1]?.View)) && (<li>
                                    <Link className={(LocationURL === FRANCHISE_USER_REPORT_URL || LocationURL === CREATE_FRANCHISE_USER_REPORT_URL || LocationEditURL === "edit-franchise-user-report") ? "active" : ""} to={FRANCHISE_USER_REPORT_URL} onClick={() => CommonNavigationsChange()}>Create Franchise</Link>
                                </li>)}
                                {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[2]?.Edit || PermissionsAccess?.AdminTools[2]?.View)) && (<li>
                                    <Link className={(LocationURL === FRANCHISE_TYPE_REPORT_URL || LocationURL === ADD_FRANCHISE_TYPE_REPORT_URL || LocationEditURL === "edit-franchise-type-report") ? "active" : ""} to={FRANCHISE_TYPE_REPORT_URL} onClick={() => CommonNavigationsChange()}>Franchise Type</Link>
                                </li>)}
                                {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[3]?.Edit || PermissionsAccess?.AdminTools[3]?.View)) && (<li>
                                    <Link className={LocationURL === RM_LOGIN_URL ? "active" : ""} to={RM_LOGIN_URL} onClick={() => CommonNavigationsChange()}>Regional Manager Login</Link>
                                </li>)}
                            </li>
                        </ul>
                    </div>
                </Collapse>
                
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Masters?.filter((elm) => elm?.API !== "")?.length > 0) && (
                    <Nav.Link className={Object.assign(menuStates)["menu2"] ? "active" : ""} onClick={() => toggleMenu('menu2')} aria-controls="menu2" aria-expanded={menuStates.menu2}>
                        <i className="bi bi-mortarboard"></i>
                        Masters
                        <span className={Object.assign(menuStates)["menu2"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                    </Nav.Link>
                )}
                <Collapse className="collapnseDrop" in={menuStates.menu2}>
                    <div id="menu2">
                        <ul>
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Masters[0]?.Edit || PermissionsAccess?.Masters[0]?.View)) && (<li>
                                <Link className={(LocationURL === STATES_URL || LocationURL === ADD_STATES_URL) ? "active" : ""} to={STATES_URL} onClick={() => CommonNavigationsChange()}>States</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Masters[1]?.Edit || PermissionsAccess?.Masters[1]?.View)) && (<li>
                                <Link className={(LocationURL === CITIES_URL || LocationURL === ADD_CITIES_URL) ? "active" : ""} to={CITIES_URL} onClick={() => CommonNavigationsChange()}>Cities</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Masters[2]?.Edit || PermissionsAccess?.Masters[2]?.View)) && (<li>
                                <Link className={(LocationURL === PINE_CODES_URL || LocationURL === ADD_PINCODES_URL || LocationEditURL === "edit-pincode") ? "active" : ""} to={PINE_CODES_URL} onClick={() => CommonNavigationsChange()}>Pincodes</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Masters[3]?.Edit || PermissionsAccess?.Masters[3]?.View)) && (<li>
                                <Link className={LocationURL === BOX_URL ? "active" : ""} to={BOX_URL} onClick={() => CommonNavigationsChange()}>Box</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Masters[4]?.Edit || PermissionsAccess?.Masters[4]?.View)) && (<li>
                                <NavLink to={COURIERS_URL} onClick={() => CommonNavigationsChange()}>Couriers</NavLink>
                            </li>)}
                        </ul>
                    </div>
                </Collapse>
            </Nav>) : MainNavUrl === "Franchise" ? (<Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement?.filter((elm) => elm?.API !== "")?.length > 0) && (<Nav.Link className={Object.assign(menuStates)["menu1"] ? "active" : ""} onClick={() => toggleMenu('menu1')} aria-controls="menu1" aria-expanded={menuStates.menu1}>
                    <i className="bi bi-shop"></i>
                    Order Management
                    <span className={Object.assign(menuStates)["menu1"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                </Nav.Link>)}
                <Collapse className="collapnseDrop" in={menuStates.menu1}>
                    <div id="menu1">
                        <ul>
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[0]?.Edit || PermissionsAccess?.OrderManagement[0]?.View)) && (
                                <li><Link className={LocationURL === FRANCHISE_ORDERSREPORT_URL ? "active" : ""} to={FRANCHISE_ORDERSREPORT_URL} onClick={() => CommonNavigationsChange()}>All Orders Report</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[1]?.Edit || PermissionsAccess?.OrderManagement[1]?.View)) && (
                                <li><Link className={LocationURL === PURCHASE_ORDER_URL ? "active" : ""} to={PURCHASE_ORDER_URL} onClick={() => CommonNavigationsChange()}>SO Creation</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[2]?.Edit || PermissionsAccess?.OrderManagement[2]?.View)) && (
                                <li><Link className={(LocationURL === PO_POSTING_URL || LocationEditURL === "so-approval") ? "active" : ""} to={PO_POSTING_URL} onClick={() => CommonNavigationsChange()}>SO Approvals</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[3]?.Edit || PermissionsAccess?.OrderManagement[3]?.View)) && (
                                <li><NavLink className={(LocationURL === PO_APPROVALS_URL || LocationEditURL === "warehouse-approval") ? "active" : ""} to={PO_APPROVALS_URL} onClick={() => CommonNavigationsChange()}>Warehouse Approval</NavLink></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[5]?.Edit || PermissionsAccess?.OrderManagement[5]?.View)) && (
                                <li><NavLink className={LocationURL === FRANCHISE_DISPATCH_URL ? "active" : ""} to={FRANCHISE_DISPATCH_URL} onClick={() => CommonNavigationsChange()}>Pending For Stores</NavLink></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[6]?.Edit || PermissionsAccess?.OrderManagement[6]?.View)) && (
                                <li><NavLink className={LocationURL === FRANCHISE_DISPATCH_PENDING_URL ? "active" : ""} to={FRANCHISE_DISPATCH_PENDING_URL} onClick={() => CommonNavigationsChange()}>Pending For Dispatch</NavLink></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[7]?.Edit || PermissionsAccess?.OrderManagement[7]?.View)) && (
                                <li><NavLink className={LocationURL === FRANCHISE_PENDING_DELIVERY_URL ? "active" : ""} to={FRANCHISE_PENDING_DELIVERY_URL} onClick={() => CommonNavigationsChange()}>Pending For Delivery</NavLink></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[8]?.Edit || PermissionsAccess?.OrderManagement[8]?.View)) && (
                                <li><Link className={LocationURL === PUR_ORDER_DELIVERED_URL ? "active" : ""} to={PUR_ORDER_DELIVERED_URL} onClick={() => CommonNavigationsChange()}>Order Delivered</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[9]?.Edit || PermissionsAccess?.OrderManagement[9]?.View)) && (
                                <li><Link className={LocationURL === PUR_INVOICE_DETAILS_URL ? "active" : ""} to={PUR_INVOICE_DETAILS_URL} onClick={() => CommonNavigationsChange()}>Rejected</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[10]?.Edit || PermissionsAccess?.OrderManagement[10]?.View)) && (
                                <li><Link className={LocationURL === ORDER_CANCELLED_URL ? "active" : ""} to={ORDER_CANCELLED_URL} onClick={() => CommonNavigationsChange()}>
                                    Cancellation
                                </Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[11]?.Edit || PermissionsAccess?.OrderManagement[11]?.View)) && (
                                <li><Link className={LocationURL === E_INVOICE_DETAILS_URL ? "active" : ""} to={E_INVOICE_DETAILS_URL} onClick={() => CommonNavigationsChange()}>E-Invoice Details</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[12]?.Edit || PermissionsAccess?.OrderManagement[12]?.View)) && (
                                <li><Link className={LocationURL === E_INVOICE_CANCEL_URL ? "active" : ""} to={E_INVOICE_CANCEL_URL} onClick={() => CommonNavigationsChange()}>E-Invoice Cancel</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[13]?.Edit || PermissionsAccess?.OrderManagement[13]?.View)) && (
                                <li><Link className={LocationURL === E_WAY_BILL_URL ? "active" : ""} to={E_WAY_BILL_URL} onClick={() => CommonNavigationsChange()}>E-Way Bill</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[14]?.Edit || PermissionsAccess?.OrderManagement[14]?.View)) && (
                                <li><Link className={(LocationURL === RETURN_AND_REPLACE_REPORT_LIST || LocationURL === RETURN_AND_REPLACE_REPORT) ? "active" : ""} to={RETURN_AND_REPLACE_REPORT_LIST} onClick={() => CommonNavigationsChange()}>Return And Replace</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[15]?.Edit || PermissionsAccess?.OrderManagement[15]?.View)) && (
                                <li><Link className={LocationURL === ERP_API_FAILURES_LIST ? "active" : ""} to={ERP_API_FAILURES_LIST} onClick={() => CommonNavigationsChange()}>Erp Api Failures</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[16]?.Edit || PermissionsAccess?.OrderManagement[16]?.View)) && (
                                <li><Link className={(LocationURL === FBA_ORDER_LIST || LocationEditURL === "fba-orders-details") ? "active" : ""} to={FBA_ORDER_LIST} onClick={() => CommonNavigationsChange()}>FBA Orders</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[17]?.Edit || PermissionsAccess?.OrderManagement[17]?.View)) && (
                                <li><Link className={LocationURL === RE_ASSIGN_WAREHOUSE_LIST ? "active" : ""} to={RE_ASSIGN_WAREHOUSE_LIST} onClick={() => CommonNavigationsChange()}>Re-Assign Warehouse</Link></li>
                            )}
                        </ul>
                    </div>
                </Collapse>
 
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.PaymentManager?.filter((elm) => elm?.API !== "")?.length > 0) && (
                    <Nav.Link className={Object.assign(menuStates)["menu2"] ? "active" : ""} onClick={() => toggleMenu('menu2')} aria-controls="menu2" aria-expanded={menuStates.menu2}>
                        <i className="bi bi-credit-card"></i>
                        Payment Manager
                        <span className={Object.assign(menuStates)["menu2"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                    </Nav.Link>
                )}
                <Collapse className="collapnseDrop" in={menuStates.menu2}>
                    <div id="menu2">
                        <ul>
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[0]?.Edit || PermissionsAccess?.PaymentManager[0]?.View)) && (
                                <li><Link className={LocationURL === ACCOUNT_LEDGER_REPORT ? "active" : ""} to={ACCOUNT_LEDGER_REPORT} onClick={() => CommonNavigationsChange()}>Account Ledger Report</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[1]?.Edit || PermissionsAccess?.PaymentManager[1]?.View)) && (
                                <li><Link className={(LocationURL === DEBIT_CREDIT_NOTES || LocationEditURL === "debit-credit-notes") ? "active" : ""} to={DEBIT_CREDIT_NOTES} onClick={() => CommonNavigationsChange()}>Debit/Credit Notes</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[2]?.Edit || PermissionsAccess?.PaymentManager[2]?.View)) && (
                                <li><Link className={LocationURL === DEBIT_CREDIT_NOTE_REPORT? "active" : ""} to={DEBIT_CREDIT_NOTE_REPORT} onClick={() => CommonNavigationsChange()}>Debit/Credit Note Report</Link></li>
                            )}
                            {/* <li><Link className={LocationURL === DEPOSIT_REPORT? "active" : ""} to={DEPOSIT_REPORT}>Deposit Report</Link></li> */}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[3]?.Edit || PermissionsAccess?.PaymentManager[3]?.View)) && (
                                <li><Link className={LocationURL === PAYMENT_APPROVAL? "active" : ""} to={PAYMENT_APPROVAL} onClick={() => CommonNavigationsChange()}>Payment Approval</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[4]?.Edit || PermissionsAccess?.PaymentManager[4]?.View)) && (
                                <li><Link className={LocationURL === GST_DATA_REPORT ? "active" : ""} to={GST_DATA_REPORT} onClick={() => CommonNavigationsChange()}>GST Data Report</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[5]?.Edit || PermissionsAccess?.PaymentManager[5]?.View)) && (
                                <li><Link className={LocationURL === ERP_OUTSTANDING_REPORT ? "active" : ""} to={ERP_OUTSTANDING_REPORT} onClick={() => CommonNavigationsChange()}>ERP Outstanding Report</Link></li>
                            )}
                        </ul>
                    </div>
                </Collapse>
            </Nav>) : (<Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.CRMTools?.filter((elm) => elm?.API !== "")?.length > 0) && (
                    <Nav.Link className={Object.assign(menuStates)["menu1"] ? "active" : ""} onClick={() => toggleMenu('menu1')} aria-controls="menu1" aria-expanded={menuStates.menu1}>
                        <i className="bi bi-pc-display"></i>
                        CRM
                        <span className={Object.assign(menuStates)["menu1"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                    </Nav.Link>
                )}
                <Collapse className="collapnseDrop" in={menuStates.menu1}>
                    <div id="menu1">
                        <ul>
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.CRMTools[0]?.Edit || PermissionsAccess?.CRMTools[0]?.View)) && (<li>
                                <Link className={(LocationURL === REGION_MASTER_URL || LocationURL === ADD_REGION_MASTER_URL || LocationEditURL === "edit-region-master") ? "active" : ""} to={REGION_MASTER_URL}>Region Master</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.CRMTools[1]?.Edit || PermissionsAccess?.CRMTools[1]?.View)) && (<li>
                                <Link className={(LocationURL === PURCHASE_LIST_URL || LocationEditURL === "edit-purchase") ? "active" : ""} to={PURCHASE_LIST_URL}>Purchase List</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.CRMTools[2]?.Edit || PermissionsAccess?.CRMTools[2]?.View)) && (<li>
                                <Link className={LocationURL === SALES_PERSON_PERFORMANCE_URL ? "active" : ""} to={SALES_PERSON_PERFORMANCE_URL}>Sales Person Performance</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.CRMTools[3]?.Edit || PermissionsAccess?.CRMTools[3]?.View)) && (<li>
                                <Link className={LocationURL === INCENTIVE_SLAB_URL ? "active" : ""} to={INCENTIVE_SLAB_URL}>Incentive Slab</Link>
                            </li>)}
                        </ul>
                    </div>
                </Collapse>
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells?.filter((elm) => elm?.API !== "")?.length > 0) && (
                    <Nav.Link className={Object.assign(menuStates)["menu2"] ? "active" : ""} onClick={() => toggleMenu('menu2')} aria-controls="menu1" aria-expanded={menuStates.menu1}>
                        <i className="bi bi-receipt"></i>
                        Re-Sale
                        <span className={Object.assign(menuStates)["menu2"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                    </Nav.Link>
                )}
                <Collapse className="collapnseDrop" in={menuStates.menu2}>
                    <div id="menu2">
                        <ul>
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ReSells[0]?.Edit || PermissionsAccess?.ReSells[0]?.View)) && (<li>
                                <Link className={LocationURL === REFURBISHED_REPORT_LIST ? "active" : ""} to={REFURBISHED_REPORT_LIST} onClick={() => CommonNavigationsChange()}>Resellcycle Reports</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ReSells[1]?.Edit || PermissionsAccess?.ReSells[1]?.View)) && (<li>
                                <Link className={(LocationURL === RESALE_BRANDS_URL || LocationURL === ADD_RESALE_BRANDS_URL || LocationEditURL === "edit-resale-brand") ? "active" : ""} to={RESALE_BRANDS_URL} onClick={() => CommonNavigationsChange()}>Re-sele Brands</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ReSells[2]?.Edit || PermissionsAccess?.ReSells[2]?.View)) && (<li>
                                <Link className={LocationURL === SETTINGS_REFURBISHED_REPORT ? "active" : ""} to={SETTINGS_REFURBISHED_REPORT} onClick={() => CommonNavigationsChange()}>Settings</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ReSells[3]?.Edit || PermissionsAccess?.ReSells[3]?.View)) && (<li>
                                <Link className={(LocationURL === AGE_VALUE_REPORT || LocationURL === ADD_AGE_VALUE_REPORT || LocationEditURL === "edit-age-value") ? "active" : ""} to={AGE_VALUE_REPORT} onClick={() => CommonNavigationsChange()}>Age Value</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ReSells[4]?.Edit || PermissionsAccess?.ReSells[4]?.View)) && (<li>
                                <Link className={(LocationURL === POINT_VALUE_REFURBISHED_REPORT || LocationURL === ADD_POINT_VALUE_REFURBISHED_REPORT || LocationEditURL === "edit-point" || LocationURL === ADD_POINT_VALUE_SETTINGS || LocationEditURL === "edit-point-settings") ? "active" : ""} to={POINT_VALUE_REFURBISHED_REPORT} onClick={() => CommonNavigationsChange()}>Point List</Link>
                            </li>)} 
                        </ul>
                    </div>
                </Collapse>
            </Nav>)}
        </Scrollbars>
    </div>)
};

export default SidebarChild;